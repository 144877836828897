import * as RemoveIconThin from "@assets/images/svg/remove-icon-thin.svg";
import * as EyeIcon from "@assets/images/svg/eye.svg";
import * as EyeSlashIcon from "@assets/images/svg/eye-slash.svg";
import * as SpriteSortIcon from "@assets/images/svg/sprite-icon-sort.svg";
import * as SpriteIcon from "@assets/images/svg/sprite-icon.svg";
import * as ArrowUpIcon from "@assets/images/svg/arrow-trg-up.svg";
import * as ArrowDownIcon from "@assets/images/svg/arrow-trg-down.svg";
import * as ArrowThinLeftIcon from "@assets/images/svg/arrow-thin-left.svg";
import * as ArrowThinRightIcon from "@assets/images/svg/arrow-thin-right.svg";

import * as Home from "@assets/images/home.png";
import * as Home2 from "@assets/images/home2.png";
import * as Finace from "@assets/images/finance.png";
import * as Finace2 from "@assets/images/finance2.png";
import * as Market from "@assets/images/market.png";
import * as Market2 from "@assets/images/market2.png";
import * as Transaction from "@assets/images/transaction.png";
import * as Transaction2 from "@assets/images/transaction2.png";
import * as User from "@assets/images/user.png";
import * as User2 from "@assets/images/user2.png";
import * as Background from "@assets/images/background.png";
import * as Logo from "@assets/images/logo.jpg";
import * as Logo2 from "@assets/images/logo.png";
import * as Eye from "@assets/images/eye.png";
import * as Hidden from "@assets/images/hidden.png";
import * as Recharge from "@assets/images/recharge.png";
import * as RechargeYellow from "@assets/images/recharge.yellow.png";
import * as Money from "@assets/images/money.png";
import * as MoneyYellow from "@assets/images/money.yellow.png";
import * as History from "@assets/images/history.png";
import * as Messenger from "@assets/images/messenger.png";
import * as Seen from "@assets/images/seen.png";
import * as Dot from "@assets/images/dot.png";
import * as Bank from "@assets/images/bank.png";
import * as MoneySack from "@assets/images/money-sack.png";
import * as World from "@assets/images/world.png";
import * as ExchangeRate from "@assets/images/exchange-rate.png";
import * as Padlock from "@assets/images/padlock.png";
import * as Out from "@assets/images/out.png";
import * as Info from "@assets/images/info.png";
import * as Back from "@assets/images/back_fcd535.png";
import * as Transfer from "@assets/images/transfer.png";
import * as Vietnamese from "@assets/images/vietnam.png";
import * as English from "@assets/images/english.png";
import * as Chinese from "@assets/images/china.png";
import * as Japan from "@assets/images/japan.png";
import * as Korean from "@assets/images/korean.png";
import * as France from "@assets/images/france.png";
import * as Germany from "@assets/images/germany.png";

export const Images = {
  RemoveIconThin,
  EyeIcon,
  EyeSlashIcon,
  SpriteSortIcon,
  SpriteIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowThinLeftIcon,
  ArrowThinRightIcon,
  Home,
  Home2,
  Finace,
  Finace2,
  Market,
  Market2,
  Transaction,
  Transaction2,
  User,
  User2,
  Background,
  Logo,
  Logo2,
  Eye,
  Hidden,
  Recharge,
  RechargeYellow,
  Money,
  MoneyYellow,
  History,
  Messenger,
  Seen,
  Dot,
  Bank,
  MoneySack,
  World,
  ExchangeRate,
  Padlock,
  Out,
  Info,
  Back,
  Transfer,
  Vietnamese,
  English,
  Japan,
  Korean,
  France,
  Germany,
  Chinese,
};
