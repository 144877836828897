export enum WithdrawState {
  PENDING = "pending",
  REJECT = "reject",
  SUCCESS = "success",
}

export const WithdrawStateName = {
  [WithdrawState.PENDING]: "Chờ xử lý",
  [WithdrawState.SUCCESS]: "Thành công",
  [WithdrawState.REJECT]: "Đã hủy",
};
