import { addToast } from "@app/components/toast/toast.service";
import {
  ImageTypes,
  LANGUAGE_KEY,
  PathnameUrl,
  TypeCard,
} from "@app/constants";
import AccountService from "@app/services/http/account.service";
import { VerifyAccountRequest } from "@app/types";
import useObservable from "@core/hooks/use-observable.hook";
import StorageService from "@core/services/storage";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function IdentityCardAccount() {
  const navigate = useNavigate();

  const [frontCard, setFrontCard] = useState<File>();
  const [frontCardError, setFrontCardError] = useState("");
  const [frontCardObj, setFrontCardObj] = useState("");

  const [backCard, setBackCard] = useState<File>();
  const [backCardError, setBackCardError] = useState("");
  const [backCardObj, setBackCardObj] = useState("");

  const { subscribeOnce } = useObservable();

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const language = StorageService.get(LANGUAGE_KEY);

    language && i18n.changeLanguage(language);
  }, []);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const cardName = e.target.name as TypeCard;

    if (!e.target.files?.length) {
      e.target.value = "";

      return;
    }

    if (!ImageTypes.includes(e.target.files[0].type)) {
      addToast({
        text: translate("user.identity_card_account.err1"),
        status: "inValid",
      });
    }

    const file = e.target.files[0];

    switch (cardName) {
      case TypeCard.FRONT_CARD: {
        setFrontCard(file);
        const frontCardObj = URL.createObjectURL(file);

        setFrontCardObj(frontCardObj);
        setFrontCardError("");
        break;
      }
      case TypeCard.BACK_CARD: {
        setBackCard(file);
        const backCardObj = URL.createObjectURL(file);

        setBackCardObj(backCardObj);
        setBackCardError("");
        break;
      }
    }

    e.target.value = "";
  };

  const handleSubmit = () => {
    if (!frontCard) {
      setFrontCardError(translate("user.identity_card_account.err2"));

      return;
    }

    if (!backCard) {
      setBackCardError(translate("user.identity_card_account.err2"));

      return;
    }

    const verifyAccountRequest: VerifyAccountRequest = {
      front_card: frontCard,
      back_card: backCard,
    };

    subscribeOnce(AccountService.verifyAccount(verifyAccountRequest), () => {
      addToast({ text: translate("user.identity_card_account.success") });
      navigate(PathnameUrl.USER);
    });
  };

  return (
    <div className="fade-in bg-[#181a20] min-h-screen flex flex-col items-center p-6">
      <div className="bg-white rounded-lg shadow-md p-6 max-w-md w-full relative">
        {/* Close Button */}
        <button
          onClick={() => navigate(-1)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <i className="fas fa-times text-2xl"></i>
        </button>

        {/* Title */}
        <h1 className="text-2xl font-bold text-gray-800 text-center mb-2">
          {translate("user.identity_card_account.title")}
        </h1>

        {/* Subtitle */}
        <p className="text-sm text-gray-600 text-center mb-6">
          {translate("user.identity_card_account.des")}
        </p>

        {/* File Upload Sections */}
        <div className="space-y-4">
          {/* Front ID Card Upload */}
          <div className="flex flex-col items-center bg-gray-50 p-4 rounded-lg border border-gray-200">
            <i className="fas fa-id-card text-yellow-500 text-4xl mb-2"></i>
            <label className="text-gray-700 font-semibold mb-2">
              {translate("user.identity_card_account.label_front")}
            </label>
            <input
              type="file"
              className="text-sm text-gray-600"
              name={TypeCard.FRONT_CARD}
              onChange={handleFileChange}
            />
            {frontCard && (
              <img className="w-full max-h-[200px] mt-2" src={frontCardObj} />
            )}
            <div className="text-[#D60000B2] text-sm">{frontCardError}</div>
          </div>

          {/* Back ID Card Upload */}
          <div className="flex flex-col items-center bg-gray-50 p-4 rounded-lg border border-gray-200">
            <i className="fas fa-id-card text-yellow-500 text-4xl mb-2"></i>
            <label className="text-gray-700 font-semibold mb-2">
              {translate("user.identity_card_account.label_back")}
            </label>
            <input
              type="file"
              className="text-sm text-gray-600"
              name={TypeCard.BACK_CARD}
              onChange={handleFileChange}
            />
            {backCard && (
              <img className="w-full max-h-[200px] mt-2" src={backCardObj} />
            )}
            <div className="text-[#D60000B2] text-sm">{backCardError}</div>
          </div>
        </div>

        {/* Verify Button */}
        <button
          className="mt-6 w-full bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-3 rounded-lg"
          onClick={handleSubmit}
        >
          {translate("btn.btn_confirm")}
        </button>
      </div>
    </div>
  );
}

export default IdentityCardAccount;
