import SymbolComponent from "@app/components/charts/symbol.component";
import TopStories from "@app/components/charts/top.stories.component";

function Finance() {
  return (
    <div className="fade-in bg-[#181a20]">
      <SymbolComponent />
      <div className="h-2"></div>
      <TopStories />
    </div>
  );
}

export default Finance;
