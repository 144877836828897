import { RouterProvider } from "react-router-dom";
import ModalContainer from "./components/modal/modal-container";
import ToastContainer from "./components/toast/toast.container";
import router from "./router";
import "./styles/app.scss";
import Loading from "@core/components/loading";
// import { Subscription } from "rxjs";
// import { useEffect } from "react";
// import SocketService from "./services/socket.service";

function App() {
  // useEffect(() => {
  //   SocketService.connect();
  // }, []);

  return (
    <>
      <RouterProvider router={router} />
      <div id="toast-root">
        <ToastContainer />
      </div>
      <div id="modal-root">
        <ModalContainer />
      </div>
      <div id="loading-root">
        <Loading />
      </div>
    </>
  );
}

export default App;
