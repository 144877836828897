// TradingViewMarketOverview.tsx
import {
  getLanguageKeyForChart,
  Language,
  LANGUAGE_KEY,
  PathnameUrl,
} from "@app/constants";
import StorageService from "@core/services/storage";
import React, { memo, MouseEvent, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const MarketOverview: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const l = StorageService.get(LANGUAGE_KEY);

    const language = getLanguageKeyForChart(l as Language);

    const script = document.createElement("script");

    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      colorTheme: "dark",
      dateRange: "12M",
      showChart: true,
      locale: language,
      largeChartUrl: "",
      isTransparent: false,
      showSymbolLogo: true,
      showFloatingTooltip: false,
      width: "100%",
      height: "600",
      plotLineColorGrowing: "rgba(41, 98, 255, 1)",
      plotLineColorFalling: "rgba(41, 98, 255, 1)",
      gridLineColor: "rgba(42, 46, 57, 0)",
      scaleFontColor: "rgba(209, 212, 220, 1)",
      belowLineFillColorGrowing: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorFalling: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorGrowingBottom: "rgba(41, 98, 255, 0)",
      belowLineFillColorFallingBottom: "rgba(41, 98, 255, 0)",
      symbolActiveColor: "rgba(41, 98, 255, 0.12)",
      tabs: [
        {
          title: "Indices",
          symbols: [
            {
              s: "MEXC:BTCUSDT",
            },
            { s: "FOREXCOM:SPXUSD", d: "S&P 500 Index" },
            { s: "FOREXCOM:NSXUSD", d: "US 100 Cash CFD" },
            { s: "FOREXCOM:DJI", d: "Dow Jones Industrial Average Index" },
            { s: "INDEX:NKY", d: "Nikkei 225" },
            { s: "INDEX:DEU40", d: "DAX Index" },
            { s: "FOREXCOM:UKXGBP", d: "FTSE 100 Index" },
          ],
          originalTitle: "Indices",
        },
        {
          title: "Futures",
          symbols: [
            { s: "CME_MINI:ES1!", d: "S&P 500" },
            { s: "CME:6E1!", d: "Euro" },
            { s: "COMEX:GC1!", d: "Gold" },
            { s: "NYMEX:CL1!", d: "WTI Crude Oil" },
            { s: "NYMEX:NG1!", d: "Gas" },
            { s: "CBOT:ZC1!", d: "Corn" },
          ],
          originalTitle: "Futures",
        },
        {
          title: "Bonds",
          symbols: [
            { s: "CBOT:ZB1!", d: "T-Bond" },
            { s: "CBOT:UB1!", d: "Ultra T-Bond" },
            { s: "EUREX:FGBL1!", d: "Euro Bund" },
            { s: "EUREX:FBTP1!", d: "Euro BTP" },
            { s: "EUREX:FGBM1!", d: "Euro BOBL" },
          ],
          originalTitle: "Bonds",
        },
        {
          title: "Forex",
          symbols: [
            { s: "FX:EURUSD", d: "EUR to USD" },
            { s: "FX:GBPUSD", d: "GBP to USD" },
            { s: "FX:USDJPY", d: "USD to JPY" },
            { s: "FX:USDCHF", d: "USD to CHF" },
            { s: "FX:AUDUSD", d: "AUD to USD" },
            { s: "FX:USDCAD", d: "USD to CAD" },
          ],
          originalTitle: "Forex",
        },
      ],
    });

    containerRef.current.appendChild(script);
  }, []);

  const navigate = useNavigate();
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    navigate(PathnameUrl.TRANSACTION); // Thay bằng URL mong muốn
  };

  return (
    <div className="tradingview-widget-container relative" ref={containerRef}>
      <div className="tradingview-widget-container__widget"></div>
      <div
        onClick={handleClick}
        style={{
          position: "absolute",
          top: "240px",
          left: "60px",
          bottom: 0,
          width: "70px",
          // backgroundColor: "rgba(1,1,1,0.4)",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          right: "0",
          bottom: "0",
          width: "30px",
          height: "40px",
          // backgroundColor: "rgba(1,1,1,0.4)",
          backgroundColor: "transparent",
        }}
      ></div>
    </div>
  );
};

export default memo(MarketOverview);
