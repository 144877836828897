import MenuLinkItem from "@app/components/menu-link-item";
import {
  ACCESS_TOKEN_KEY,
  Menu,
  SocketEvent,
  USER_INFO_KEY,
} from "@app/constants";
import AuthService from "@app/services/http/auth.service";
import SocketService from "@app/services/socket.service";
import { clearUser } from "@app/store/auth/auth.action";
import useObservable from "@core/hooks/use-observable.hook";
import StorageService from "@core/services/storage";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

function MainLayout() {
  const { subscribeOnce } = useObservable();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    subscribeOnce(AuthService.verifyToken(), (data) => {
      if (data.statusCode !== 200) {
        dispatch(clearUser());
        StorageService.remove(ACCESS_TOKEN_KEY);
        navigate("/login");
      } else {
        SocketService.connect({ auth: { token: data.token } });

        const acc = StorageService.getObject(USER_INFO_KEY);

        SocketService.emit(SocketEvent.JOIN_ROOM, (acc as any)._id + "");
      }
    });
  }, []);

  return (
    <div className="bg-black flex flex-col w-full h-[calc(100vh-64px)] scroll-smooth">
      <div>
        <div>
          <Outlet />
        </div>
      </div>
      <div className="p-2 flex transition-all fixed bottom-0 w-full h-[64px] bg-[#1e2329] border-solid border-[#eee]">
        {Menu.map((item, index) => (
          <MenuLinkItem key={index} {...item} onMenuItemClick={() => {}} />
        ))}
      </div>
    </div>
  );
}

export default MainLayout;
