import { LANGUAGE_KEY, PathnameUrl } from "@app/constants";
import StorageService from "@core/services/storage";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Abouts() {
  const [t, i18n] = useTranslation("global");

  const navigate = useNavigate();

  //ngoon nguw
  useEffect(() => {
    const l = StorageService.get(LANGUAGE_KEY);

    if (l) {
      i18n.changeLanguage(l);
    }
  }, []);

  const features = t("about.features.items", { returnObjects: true }) as {
    title: string;
    description: string;
  }[];

  const testimonials = t("about.testimonials.items", {
    returnObjects: true,
  }) as {
    name: string;
    feedback: string;
    image: string;
  }[];

  return (
    <div>
      <header className="flex items-center bg-blue-600 text-white p-4 shadow-lg">
        <i
          className="fa-solid fa-chevron-left"
          onClick={() => navigate(PathnameUrl.USER)}
        ></i>
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">{t("about.header.title")}</h1>
          <nav>
            <ul className="flex gap-6">
              <li>
                <a href="#features" className="hover:text-gray-200">
                  {t("about.header.features")}
                </a>
              </li>
              <li>
                <a href="#testimonials" className="hover:text-gray-200">
                  {t("about.header.testimonials")}
                </a>
              </li>
              <li>
                <a href="#contact" className="hover:text-gray-200">
                  {t("about.header.contact")}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <section className="bg-gradient-to-r from-blue-600 to-purple-500 text-white py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-4">{t("about.hero.welcome")}</h2>
          <p className="text-lg mb-6">{t("about.hero.description")}</p>
          <button
            onClick={() => navigate(PathnameUrl.TRANSACTION)}
            className="bg-yellow-400 hover:bg-yellow-300 text-black font-bold py-2 px-4 rounded"
          >
            {t("about.hero.getStarted")}
          </button>
        </div>
      </section>
      <section id="features" className="py-16 bg-white">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-bold mb-8">
            {t("about.features.heading")}
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="p-6 bg-gray-100 rounded-lg shadow-md">
                <h4 className="text-xl font-bold mb-2">{feature.title}</h4>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="testimonials" className="py-16 bg-gray-50">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-bold mb-8">
            {t("about.testimonials.heading")}
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="p-6 bg-white rounded-lg shadow-md">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-16 h-16 rounded-full mx-auto mb-4"
                />
                <p className="italic mb-2">{testimonial.feedback}</p>
                <h4 className="font-bold">{testimonial.name}</h4>
              </div>
            ))}
          </div>
        </div>
      </section>
      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto text-center">
          <p>{t("about.footer.text")}</p>
        </div>
      </footer>
    </div>
  );
}

export default Abouts;
