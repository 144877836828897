import { PathnameUrl, SocketEvent } from "@app/constants";
import MessageService from "@app/services/http/message.service";
import SocketService from "@app/services/socket.service";
import { Message } from "@app/types";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Subscription } from "rxjs";

function Chat() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState("");
  const [page] = useState(1);
  const [file] = useState<File | null>(null);
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const refInputMessage = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const { subscribeOnce } = useObservable();

  // Handle send message
  const handleSendMessage = async () => {
    if (!message.trim() && !file) return;

    // const formData = new FormData();

    // formData.append("text", message);

    // if (file) formData.append("file", file);

    // const response = await axios.post("/api/messages", formData);
    // setMessages((prev) => [...prev, response.data.message]);

    SocketService.emit(SocketEvent.MESSAGE, message);
    refInputMessage.current?.focus();
    setMessage("");
    // setFile(null);
  };

  //call get messages
  useEffect(() => {
    subscribeOnce(MessageService.getMessages(page), (res) => {
      setMessages(res.data);

      if (chatBoxRef.current) {
        setTimeout(() => {
          chatBoxRef.current!.scrollTop = chatBoxRef.current!.scrollHeight;
        }, 10);
      }
    });
  }, []);

  // Connect to socket
  useEffect(() => {
    const subscription = new Subscription();

    const sub = SocketService.onMessage().subscribe((data) => {
      setMessages((pre) => {
        const newMessages = [...pre, data];

        return newMessages;
      });

      if (chatBoxRef.current) {
        setTimeout(() => {
          chatBoxRef.current!.scrollTop = chatBoxRef.current!.scrollHeight;
        }, 10);
      }
    });

    subscription.add(sub);

    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className="flex flex-col h-[100%] overflow-y-auto bg-gray-100 fixed w-full z-10">
      {/* Chat Header */}
      <div className="flex justify-between items-center py-2 px-4 text-center">
        <i
          onClick={() => navigate(PathnameUrl.HOME)}
          className="fa-solid fa-chevron-left"
        ></i>
        Hỗ trợ
        <i></i>
      </div>

      {/* Chat Messages */}
      <div
        className="flex-1 p-4 overflow-y-auto"
        ref={chatBoxRef}
        // onScroll={handleScroll}
      >
        {messages.map((msg) => (
          <div
            key={msg._id}
            className={`flex flex-col mb-4 ${
              msg.sender === "admin" ? "items-start" : "items-end"
            }`}
          >
            <div className="flex flex-col w-fit max-w-[60%]">
              <div>
                <div
                  className={`p-2 rounded-lg ${
                    msg.sender === "admin"
                      ? "bg-gray-300 text-black"
                      : "bg-pri-color2 text-white"
                  }`}
                >
                  {msg.imageUrl && (
                    <img
                      src={msg.imageUrl}
                      alt="sent"
                      className="mb-2 max-w-xs rounded-md"
                    />
                  )}
                  {msg.text}
                </div>
              </div>
              {/* <div className="text-xs text-gray-500">
                {new Date(msg.createdAt).toLocaleTimeString()}
              </div> */}
            </div>
          </div>
        ))}
      </div>

      {/* Chat Input */}
      <div className="relative p-2 bg-gray-200">
        {/* <input
          type="file"
          onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
          className="hidden"
          id="fileInput"
        /> */}
        {/* <label
          htmlFor="fileInput"
          className="px-4 py-2 text-white rounded-lg cursor-pointer mr-2"
        >
          <i className="fa-solid fa-image text-black"></i>
        </label> */}
        <input
          type="text"
          ref={refInputMessage}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
          className="w-full p-2 border rounded-lg"
          placeholder="Type a message..."
        />
        <button
          onClick={handleSendMessage}
          className="absolute right-4 top-[50%] translate-y-[-50%]"
        >
          <i className="fa-solid fa-paper-plane text-pri-color"></i>
        </button>
      </div>
    </div>
  );
}

export default Chat;
