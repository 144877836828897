import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Images } from "@assets/images";
import StorageService from "@core/services/storage";
import { LANGUAGE_KEY } from "@app/constants";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function ChangeLanguageModal({ portalDialogRef }: PortalDialogProps) {
  const changeLanguage = (lang: string) => {
    StorageService.set(LANGUAGE_KEY, lang);
    portalDialogRef.close({ language: lang });
  };

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const language = StorageService.get(LANGUAGE_KEY);

    language && i18n.changeLanguage(language);
  }, []);

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="fade-in-dialog fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-20">
        <div className="bg-white p-6 rounded-md shadow-lg w-11/12 md:w-3/4 lg:w-1/2">
          <div className="flex justify-between items-center">
            <h2 className="text-[20px] font-bold">
              {translate("user.change_language.title")}
            </h2>
            <i
              className="fa-solid fa-xmark text-xl cursor-pointer"
              onClick={() => portalDialogRef.close({ isAccept: false })}
            ></i>
          </div>
          <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
            <div
              onClick={() => changeLanguage("vi")}
              className="flex items-center cursor-pointer gap-4 bg-[#1e2329] py-2 px-4 rounded-lg mb-4"
            >
              <img className="w-10" src={Images.Vietnamese.default} alt="" />
              <div className="font-bold text-white">
                {translate("user.change_language.vi")}
              </div>
            </div>
            <div
              onClick={() => changeLanguage("en")}
              className="flex items-center cursor-pointer gap-4 bg-[#1e2329] py-2 px-4 rounded-lg mb-4"
            >
              <img className="w-10" src={Images.English.default} alt="" />
              <div className="font-bold text-white">
                {translate("user.change_language.en")}
              </div>
            </div>
            <div
              onClick={() => changeLanguage("zh")}
              className="flex items-center cursor-pointer gap-4 bg-[#1e2329] py-2 px-4 rounded-lg mb-4"
            >
              <img className="w-10" src={Images.Chinese.default} alt="" />
              <div className="font-bold text-white">
                {translate("user.change_language.zh")}
              </div>
            </div> 
            <div
              onClick={() => changeLanguage("ko")}
              className="flex items-center cursor-pointer gap-4 bg-[#1e2329] py-2 px-4 rounded-lg mb-4"
            >
              <img className="w-10" src={Images.Korean.default} alt="" />
              <div className="font-bold text-white">
                {translate("user.change_language.ko")}
              </div>
            </div> 
            <div
              onClick={() => changeLanguage("ja")}
              className="flex items-center cursor-pointer gap-4 bg-[#1e2329] py-2 px-4 rounded-lg mb-4"
            >
              <img className="w-10" src={Images.Japan.default} alt="" />
              <div className="font-bold text-white">
                {translate("user.change_language.ja")}
              </div>
            </div> 
            <div
              onClick={() => changeLanguage("de")}
              className="flex items-center cursor-pointer gap-4 bg-[#1e2329] py-2 px-4 rounded-lg mb-4"
            >
              <img className="w-10" src={Images.Germany.default} alt="" />
              <div className="font-bold text-white">
                {translate("user.change_language.de")}
              </div>
            </div> 
            <div
              onClick={() => changeLanguage("fr")}
              className="flex items-center cursor-pointer gap-4 bg-[#1e2329] py-2 px-4 rounded-lg mb-4"
            >
              <img className="w-10" src={Images.France.default} alt="" />
              <div className="font-bold text-white">
                {translate("user.change_language.fr")}
              </div>
            </div> 
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ChangeLanguageModal;
