import { LANGUAGE_KEY, PathnameUrl } from "@app/constants";
import StorageService from "@core/services/storage";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function IdentityAccount() {
  const navigate = useNavigate();

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const language = StorageService.get(LANGUAGE_KEY);

    language && i18n.changeLanguage(language);
  }, []);

  return (
    <div className="fade-in bg-[#181a20] min-h-screen flex flex-col items-center p-6">
      <div className="flex justify-between w-full mb-4">
        <i
          onClick={() => navigate(-1)}
          className="fa-solid fa-chevron-left text-white"
        ></i>
        <i className="fa-solid fa-circle-question text-white"></i>
      </div>
      <div className="bg-[#1e2329] rounded-lg shadow-md p-8 max-w-2xl w-full">
        {/* Title */}
        <h1 className="text-[20px] font-bold text-white mb-2 text-left">
          {translate("user.identity_account.title")}
        </h1>

        {/* Subtitle */}
        <p className="text-[11px] text-gray-200 text-left mb-6">
          {translate("user.identity_account.des")}
        </p>

        {/* Privileges Section */}
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          {/* Account Privileges */}
          <div className="bg-gray-50 p-6 rounded-lg shadow-md flex-1">
            <div className="flex justify-between">
              <h2 className="font-semibold text-gray-700 mb-4">
                {translate("user.identity_account.title_benefit")}
              </h2>
              <h2 className="font-semibold text-gray-700 mb-4">
                {translate("user.identity_account.title_after_kyc")}
              </h2>
            </div>
            <ul className="space-y-3">
              {[
                translate("user.identity_account.label_withdraw"),
                translate("user.identity_account.label_recharge_online"),
                translate("user.identity_account.label_fiat"),
                translate("user.identity_account.label_p2p"),
              ].map((privilege, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center text-gray-600 text-[14px]"
                >
                  {privilege}
                  <span className="text-green-500 mr-2">
                    <i className="fas fa-check-circle"></i>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Verification Requirement */}
        <div className="bg-white p-4 rounded-lg shadow-md border-t-4 border-yellow-500">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">
            {translate("user.identity_account.title_require_kyc")}
          </h3>
          <p className="text-gray-600 text-sm flex gap-2 items-center mb-4">
            <i className="fa-regular fa-circle-dot"></i>
            {translate("user.identity_account.label_require_1")}
          </p>
          <p className="text-gray-600 text-sm flex gap-2 items-center mb-4">
            <i className="fa-regular fa-circle-dot"></i>
            {translate("user.identity_account.label_require_2")}
          </p>
        </div>

        <div
          onClick={() => navigate(PathnameUrl.IDENTITY_CARD_ACCOUNT)}
          className="bg-white text-[#181a20] font-bold mt-4 rounded-[30px] p-3 text-center cursor-pointer"
        >
          {translate("btn.btn_continue")}
        </div>
      </div>
    </div>
  );
}

export default IdentityAccount;
