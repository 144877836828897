import { PathnameUrl } from "@app/constants";
import AccountService from "@app/services/http/account.service";
import { Account, AccountPasswordWithdraw } from "@app/types";
import { formatMoneyUSDT } from "@app/utils/util";
import { Images } from "@assets/images";
import useObservable from "@core/hooks/use-observable.hook";
import { createRef } from "react";
import { Form, Formik, FormikContextType } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormControl } from "@app/components/form-control";
import Input from "@app/components/input";
import Button from "@app/components/button";
import { passwordWithdrawValid } from "@app/validations/password.validation";

function PasswordWithdraw() {
  const [myInfo, setMyInfo] = useState<Account>();
  const { subscribeOnce } = useObservable();

  const navigate = useNavigate();

  useEffect(() => {
    subscribeOnce(AccountService.getMyInfo(), (user) => {
      setMyInfo(user);
    });
  }, []);

  const formRef = createRef<FormikContextType<AccountPasswordWithdraw>>();

  const handleSubmit = () => {};

  return (
    <div className="fade-in bg-[#181a20] p-4 text-[#EAECEF] pb-[64px] h-[100vh]">
      <h1
        onClick={() => navigate(PathnameUrl.USER)}
        className="flex items-center cursor-pointer gap-2 font-bold text-[#F0B90B] text-[20px]"
      >
        <img className="w-6" src={Images.Back.default} alt="" />
        Hồ Sơ
      </h1>
      {/* avt và tt tk */}
      <div className="flex items-center gap-4 mt-2">
        <img className="w-16 rounded-[50%]" src={myInfo?.avt} alt="" />
        <div>
          <div className="text-[16px]">{myInfo?.phone}</div>
          <div className="text-[12px] text-[#EAECEF]">ID: {myInfo?.uid}</div>
          <div className="flex gap-1 text-[12px] text-[#EAECEF]">
            USDT:
            <div className="text-[#F0B90B]">
              {formatMoneyUSDT(myInfo?.balance || 0)}
            </div>
          </div>
        </div>
      </div>
      <h1 className="my-4 text-center font-bold text-[#F0B90B] text-[20px]">
        Mật Khẩu Rút Tiền
      </h1>
      {/* formik */}
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        onSubmit={handleSubmit}
        innerRef={formRef}
        validationSchema={passwordWithdrawValid}
        validateOnChange
        validateOnBlur
      >
        <Form>
          <FormControl name="password">
            <Input
              width="auto"
              className="!w-[98%] !max-w-none mb-[22px]"
              inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
              placeholder="Mật khẩu..."
              inputElement={<i className="fa fa-lock text-[#c0c4cc]"></i>}
              errorClassName="text-[#F56C6C] text-xs"
              type="password"
            />
          </FormControl>
          <FormControl name="confirmPassword">
            <Input
              width="auto"
              className="!w-[98%] !max-w-none mb-[22px]"
              inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
              placeholder="Nhập lại mật khẩu..."
              inputElement={<i className="fa fa-lock text-[#c0c4cc]"></i>}
              errorClassName="text-[#F56C6C] text-xs"
              type="password"
            />
          </FormControl>

          <Button
            type="submit"
            width="auto"
            size="m"
            containerClassName="mt-7 max-sm:!mt-3"
            label="Cập Nhật"
            className="text-sm rounded-[20px] bg-[#fcd535] border-[#181e25] hover:bg-[#181e25] hover:border-[#181e25]"
            labelClassName="font-medium"
          />
        </Form>
      </Formik>
    </div>
  );
}

export default PasswordWithdraw;
