// Chart.tsx
import { getLanguageKeyForChart, Language, LANGUAGE_KEY } from "@app/constants";
import StorageService from "@core/services/storage";
import React, { useEffect, useRef, memo } from "react";

const Chart: React.FC = () => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!container.current) return;

    const l = StorageService.get(LANGUAGE_KEY);

    const language = getLanguageKeyForChart(l as Language);

    const script = document.createElement("script");

    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      autosize: true,
      symbol: "MEXC:BTCUSDT",
      interval: "1",
      timezone: "Asia/Bangkok",
      theme: "dark",
      style: "1",
      locale: language,
      withdateranges: true,
      hide_side_toolbar: true,
      allow_symbol_change: true,
      watchlist: ["MEXC:BTCUSDT"],
      // watchlist: ["BINANCE:BTCUSDT", "BINANCE:BTCUSD", "BITSTAMP:BTCUSD"],
      details: true,
      hotlist: true,
      calendar: false,
      support_host: "https://www.tradingview.com",
    });

    container.current.appendChild(script);
  }, []);

  return (
    <div
      className="tradingview-widget-container"
      ref={container}
      style={{ height: "100%", width: "100%" }}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "calc(100% - 32px)", width: "100%" }}
      ></div>
      <div
        style={{
          position: "fixed",
          top: "510px",
          left: "10px",
          bottom: 0,
          width: "40px",
          height: "40px",
          // backgroundColor: "rgba(1,1,1,0.4)",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      ></div>
    </div>
  );
};

export default memo(Chart);
