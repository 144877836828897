import { createBrowserRouter } from "react-router-dom";
import SignIn from "./pages/sign-in";
import SignUp from "./pages/sign-up";
import MainLayout from "./layout/main.layout";
import Home from "./pages/home";
import Market from "./pages/market";
import Transaction from "./pages/transaction";
import Finance from "./pages/finance";
import User from "./pages/user";

import { isAuthenticated } from "./loaders/authentication";
import { PathnameUrl } from "./constants";
import NotFound from "./pages/not-found";
import LogManagement from "./pages/log";
import BankInfo from "./pages/bank-info";
import PasswordWithdraw from "./pages/password-withdraw";
import ChangePassword from "./pages/change-password";
import IdentityAccount from "./pages/identity-account";
import IdentityCardAccount from "./pages/identity-card-account";
import ExchangeMoney from "./pages/exchange-money";
import Abouts from "./pages/abouts";
import Chat from "./pages/chat";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    loader: isAuthenticated,
    children: [
      {
        path: PathnameUrl.HOME,
        element: <Home />,
      },
      {
        path: PathnameUrl.MARKET,
        element: <Market />,
      },
      {
        path: PathnameUrl.TRANSACTION,
        element: <Transaction />,
      },
      {
        path: PathnameUrl.FINANCE,
        element: <Finance />,
      },
      {
        path: PathnameUrl.USER,
        element: <User />,
      },
      {
        path: PathnameUrl.LOG,
        element: <LogManagement />,
      },
      {
        path: PathnameUrl.BANK_INFO,
        element: <BankInfo />,
      },
      {
        path: PathnameUrl.PASSWORD_WITHDRAW,
        element: <PasswordWithdraw />,
      },
      {
        path: PathnameUrl.CHANGE_PASSWORD,
        element: <ChangePassword />,
      },
      {
        path: PathnameUrl.CHAT,
        element: <Chat />,
      },
    ],
  },
  {
    path: PathnameUrl.IDENTITY_ACCOUNT,
    element: <IdentityAccount />,
    loader: isAuthenticated,
  },
  {
    path: PathnameUrl.IDENTITY_CARD_ACCOUNT,
    element: <IdentityCardAccount />,
    loader: isAuthenticated,
  },
  {
    path: PathnameUrl.EXCHANGE_MONEY,
    element: <ExchangeMoney />,
    loader: isAuthenticated,
  },
  {
    path: PathnameUrl.ABOUTS,
    element: <Abouts />,
    loader: isAuthenticated,
  },
  {
    path: "/login",
    element: <SignIn />,
    loader: isAuthenticated,
  },
  {
    path: "/register",
    element: <SignUp />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
