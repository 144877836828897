import {
  getTranslatedLabel, 
  Language,
  LANGUAGE_KEY,
} from "@app/constants";
import { MenuItem } from "@app/types";
import StorageService from "@core/services/storage";
import { NavLink } from "react-router-dom";

interface MenuItemProps extends MenuItem {
  onMenuItemClick: () => void;
}

function MenuLinkItem({ linkTo, label, icon, onMenuItemClick }: MenuItemProps) {
  const language =
    (StorageService.get(LANGUAGE_KEY) as Language) ?? Language.vi;

  const l = getTranslatedLabel(label, language);

  return (
    <NavLink
      to={linkTo}
      onClick={onMenuItemClick}
      className={({ isActive }) => {
        let className =
          "flex-1 flex justify-center items-center hover:bg-[#2b3139] text-[#EAECEF] rounded-[22px]";

        if (isActive) {
          className += "text-[#5E6673] bg-[#2b3139] rounded-[22px]";
        }

        return className;
      }}
    >
      <div className="flex flex-col items-center justify-center w-full h-[100%]">
        <div className="">
          <img className="w-6" src={icon} alt="" />
        </div>
        <div className="text-[10px] text-center">{l}</div>
      </div>
    </NavLink>
  );
}

export default MenuLinkItem;
