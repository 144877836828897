import HttpService from "@core/services/http/http.service";
import { LoginResponse, SignUpRequest } from "@app/types"; 

class _AuthService {
  public login(phone: string, password: string) {
    return HttpService.post<LoginResponse>("/auth/login", {
      body: {
        phone,
        password,
      },
    });
  }

  public signUp(signUpRequest: SignUpRequest) {
    return HttpService.post<LoginResponse>("/auth/register", {
      body: { ...signUpRequest },
    });
  }

  public verifyToken() {
    return HttpService.get<any>("/auth/verify-token");
  }
}

const AuthService = new _AuthService();

export default AuthService;
