import AccountService from "@app/services/http/account.service";
import { Account, BankInitValue } from "@app/types";
import { Images } from "@assets/images";
import useObservable from "@core/hooks/use-observable.hook";
import { createRef } from "react";
import { Form, Formik, FormikContextType } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bankValidationSchema } from "@app/validations/bank.validation";
import { FormControl } from "@app/components/form-control";
import Input from "@app/components/input";
import Button from "@app/components/button";
import { addToast } from "@app/components/toast/toast.service";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import HeaderProfile from "@app/components/header-profile";
import { useTranslation } from "react-i18next";
import StorageService from "@core/services/storage";
import { LANGUAGE_KEY } from "@app/constants";

function BankInfo() {
  const [myInfo, setMyInfo] = useState<Account>();
  const { subscribeOnce } = useObservable();
  const [update, forceUpdate] = useForceUpdate();

  const navigate = useNavigate();

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const language = StorageService.get(LANGUAGE_KEY);

    language && i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    subscribeOnce(AccountService.getMyInfo(), (user) => {
      setMyInfo(user);
    });
  }, [update]);

  const formRef = createRef<FormikContextType<BankInitValue>>();

  const handleSubmit = (value: BankInitValue) => {
    subscribeOnce(AccountService.updateMyBank(value), () => {
      addToast({
        text: translate("user.bank_infor.success"),
        position: "top-right",
      });
      forceUpdate();
    });
  };

  return (
    <div className="fade-in bg-[#181a20] p-4 text-[#EAECEF] pb-[64px] h-[100vh]">
      <h1
        onClick={() => navigate(-1)}
        className="flex items-center cursor-pointer gap-2 font-bold text-[#F0B90B] text-[20px]"
      >
        <img className="w-6" src={Images.Back.default} alt="" />
        {translate("user.bank_infor.title_file")}
      </h1>
      {/* avt và tt tk */}
      {myInfo && <HeaderProfile myInfo={myInfo} />}
      <h1 className="my-4 text-center font-bold text-[#F0B90B] text-[20px]">
        {translate("user.bank_infor.title")}
      </h1>
      {/* formik */}
      <Formik
        initialValues={{
          bank_name: myInfo?.bank_name || "",
          bank_number: myInfo?.bank_number || "",
          bank_owner: myInfo?.bank_owner || "",
        }}
        onSubmit={handleSubmit}
        innerRef={formRef}
        validationSchema={bankValidationSchema}
        validateOnChange
        validateOnBlur
        enableReinitialize
      >
        <Form>
          <FormControl name="bank_name">
            <Input
              width="auto"
              className="!w-[98%] !max-w-none mb-[22px]"
              inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
              placeholder={translate("user.bank_info.text_input1")}
              inputElement={
                <i
                  className="fa-solid fa-building-columns"
                  style={{ color: "#fcd535" }}
                ></i>
              }
              errorClassName="text-[#F56C6C] text-xs"
            />
          </FormControl>
          <FormControl name="bank_number">
            <Input
              width="auto"
              className="!w-[98%] !max-w-none mb-[22px]"
              inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
              placeholder={translate("user.bank_info.text_input2")}
              inputElement={
                <i
                  className="fa-solid fa-building-columns"
                  style={{ color: "#fcd535" }}
                ></i>
              }
              errorClassName="text-[#F56C6C] text-xs"
            />
          </FormControl>
          <FormControl name="bank_owner">
            <Input
              width="auto"
              className="!w-[98%] !max-w-none mb-[22px]"
              inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
              placeholder={translate("user.bank_info.text_input3")}
              inputElement={
                <i
                  className="fa fa-user text-[#c0c4cc]"
                  style={{ color: "#fcd535" }}
                ></i>
              }
              errorClassName="text-[#F56C6C] text-xs"
            />
          </FormControl>

          <Button
            type="submit"
            width="auto"
            size="m"
            containerClassName="mt-7 max-sm:!mt-3"
            label={translate("btn.btn_update")}
            className="text-sm rounded-[20px] bg-[#fcd535] border-[#181e25] hover:bg-[#181e25] hover:border-[#181e25]"
            labelClassName="font-medium"
          />
        </Form>
      </Formik>
    </div>
  );
}

export default BankInfo;
