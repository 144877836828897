import {
  PortalDialogProps,
  openPortalDialog,
} from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { FormControl } from "../form-control";
import Input from "../input";
import Button from "../button";
import { CustomSelect } from "../select/custom-select";
import TriangleDownArrow from "../select/triangle-down-arrow";
import { createRechargeValidationSchema } from "@app/validations";
import { createRef, useEffect, useState } from "react";
import { NormalSelectOptions } from "../select/select.type";
import useObservable from "@core/hooks/use-observable.hook";
import BankService from "@app/services/http/bank.service";
import { CreateRechargeRequest, RechargeInitialValues } from "@app/types";
import RechargeService from "@app/services/http/recharge.service";
import Countdown from "react-countdown";
import { LANGUAGE_KEY, RECHARGE_COUNTDOWN_TIME } from "@app/constants";
import { useTranslation } from "react-i18next";
import StorageService from "@core/services/storage";

function QRInfoModal({ portalDialogRef, portalData }: PortalDialogProps) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const language = StorageService.get(LANGUAGE_KEY);

    language && i18n.changeLanguage(language);
  }, []);

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren={false}>
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-20">
        <div className="w-[95%] p-5 bg-white flex flex-col items-center">
          <Countdown
            date={Date.now() + RECHARGE_COUNTDOWN_TIME}
            renderer={({ minutes, seconds }) => (
              <span className="text-red-500 font-medium">
                {translate("home.recharge.t_time_out")}:{" "}
                {`0${minutes || 0}`.slice(-2)}:{`0${seconds || 0}`.slice(-2)}
              </span>
            )}
            onComplete={() => {
              portalDialogRef.close({ isCompleted: true });
            }}
          />

          <div>{isImageLoaded ? "" : translate("home.recharge.l_wait")}</div>

          <img
            width={300}
            src={portalData?.qrCode}
            onLoad={() => {
              setIsImageLoaded(true); // Khi ảnh được tải xong, cập nhật trạng thái để ẩn div
            }}
            alt={translate("home.recharge.l_wait")}
          />
          <div className="flex justify-center gap-5">
            <Button
              label={translate("btn.btn_cancel")}
              theme="danger"
              onClick={() => {
                portalDialogRef.close({ isCancel: true });
              }}
            />
            <Button
              label={translate("btn.ok")}
              onClick={() => {
                portalDialogRef.close({ isCompleted: true });
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

function CreateRechargeModal({ portalDialogRef }: PortalDialogProps) {
  const formRef = createRef<FormikContextType<RechargeInitialValues>>();

  const [banks, setBanks] = useState<NormalSelectOptions>([]);

  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const language = StorageService.get(LANGUAGE_KEY);

    language && i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    subscribeUntilDestroy(BankService.getBanks(), (banks) => {
      if (banks.length) {
        const banksDropdown: NormalSelectOptions = banks.map((bank) => ({
          icon: bank.bank_img,
          label: bank.bank_name,
          value: bank._id,
        }));

        setBanks(banksDropdown);
      } else {
        setBanks([]);
      }
    });
  }, []);

  const handleNextStep = (values: RechargeInitialValues) => {
    const createRechargeRequest: CreateRechargeRequest = {
      money: values.money,
      bank_id: values.bankId,
    };

    subscribeOnce(
      RechargeService.createRecharge(createRechargeRequest),
      (res) => {
        if (res.link_payment) {
          const qrInfoModalObs = openPortalDialog(QRInfoModal, {
            qrCode: res.link_payment,
          });

          qrInfoModalObs.afterClosed().subscribe((data) => {
            if (data?.isCancel) {
              subscribeOnce(RechargeService.cancelRecharge(res._id), () => {
                portalDialogRef.close();
              });
            }

            if (data?.isCompleted) {
              portalDialogRef.close();
            }
          });
        }
      }
    );
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-20">
        <div className="w-[90%] p-5 bg-[#1e2329] rounded-lg text-white">
          <div className="flex justify-between font-bold text-xl text-[#fcd535]">
            {translate("home.recharge.t1")}
            <i
              className="fa-solid fa-xmark text-xl cursor-pointer"
              onClick={() => portalDialogRef.close()}
            ></i>
          </div>
          <Formik
            initialValues={{ money: "", bankId: "" }}
            innerRef={formRef}
            onSubmit={handleNextStep}
            validationSchema={createRechargeValidationSchema}
            validateOnChange
            validateOnBlur
          >
            <Form>
              <div className="mt-3">
                <div className="text-sm">
                  {translate("home.recharge.l1")} (VND)
                </div>
                <FormControl name="money">
                  <Input
                    className="mt-1 !max-w-none text-sm text-[#181a20]"
                    errorClassName="text-sm"
                  />
                </FormControl>
              </div>
              <div className="mt-3">
                <div className="text-sm">{translate("home.recharge.l2")}</div>
                <FormControl name="bankId">
                  <CustomSelect
                    options={banks}
                    width="100%"
                    className="!h-9 !rounded-lg bg-white"
                    optionContainerClassName="top-[34px] w-full"
                    optionClassName="!min-h-[35px] text-sm text-black whitespace-nowrap px-2"
                    labelSelectedClassName="text-black text-sm"
                    errorClassName="!text-[#D60000B2] text-sm"
                    arrow={<TriangleDownArrow />}
                    hasBgColorHover
                  />
                </FormControl>
              </div>
              <Button
                type="submit"
                label={translate("btn.btn_continue")}
                containerClassName="mt-5 mx-auto"
                width="fit-content"
                className="px-6 text-sm bg-[#fcd535]"
                labelClassName="font-bold"
              />
            </Form>
          </Formik>
        </div>
      </div>
    </Modal>
  );
}

export default CreateRechargeModal;
