import {
  CreateTransactionRequest,
  Transaction,
  TransactionResponse,
} from "@app/types";
import HttpService from "@core/services/http/http.service";

class _TransactionService {
  public getTransactions(page: number) {
    return HttpService.get<TransactionResponse>(`/transactions?page=${page}`);
  }

  public createTransaction(createTransactionRequest: CreateTransactionRequest) {
    return HttpService.post<Transaction>("/transactions", {
      body: { ...createTransactionRequest },
    });
  }
}

const TransactionService = new _TransactionService();

export default TransactionService;
