import { MenuItem } from "@app/types";
import { Images } from "@assets/images";

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const DEFAULT_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const DEFAULT_MINUTES_SECONDS_FORMAT = "mm:ss";

export const DEFAULT_PAGE = 1;
export const DEFAULT_TOTAL_PAGES = 1;

export const ACCESS_TOKEN_KEY = "access_token";
export const CURRENT_ACCOUNT = "current_account";
export const USER_INFO_KEY = "user_info";
export const LANGUAGE_KEY = "global_language";
export const REMEMBER_ACCOUNT = "remember_account";

export const RECHARGE_COUNTDOWN_TIME = 15 * 60 * 1000; // 15 minutes, unit: ms

export enum Role {
  ADMIN = "admin",
  USER = "user",
}

export enum PathnameUrl {
  HOME = "/",
  MARKET = "/market",
  TRANSACTION = "/transaction",
  FINANCE = "/finance",
  USER = "/user",
  LOG = "/logs",
  BANK_INFO = "/bank-info",
  PASSWORD_WITHDRAW = "/password-withdraw",
  CHANGE_PASSWORD = "/change-password", 
  ABOUT = "/about",
  IDENTITY_ACCOUNT = "/identity-account",
  IDENTITY_CARD_ACCOUNT = "/identity-card-account",
  EXCHANGE_MONEY = "/exchange-money",
  ABOUTS = "/abouts",
  CHAT = "/chat",
}

export enum Language {
  vi = "vi",
  en = "en",
  zh = "zh",
  de = "de",
  fr = "fr",
  ko = "ko",
  ja = "ja",
}

export enum Label {
  HOME = "Trang Chủ",
  MARKET = "Thị Trường",
  TRANSACTION = "Giao Dịch",
  FINANCE = "Tài Chính",
  USER = "Tài Khoản",
}

export const LabelTranslate = {
  [Language.vi]: {
    [Label.HOME]: "Trang Chủ",
    [Label.MARKET]: "Thị Trường",
    [Label.TRANSACTION]: "Giao Dịch",
    [Label.FINANCE]: "Tài Chính",
    [Label.USER]: "Tài Khoản",
  },
  [Language.en]: {
    [Label.HOME]: "Home",
    [Label.MARKET]: "Market",
    [Label.TRANSACTION]: "Transaction",
    [Label.FINANCE]: "Finance",
    [Label.USER]: "User",
  },
  [Language.zh]: {
    [Label.HOME]: "首页",
    [Label.MARKET]: "市场",
    [Label.TRANSACTION]: "交易",
    [Label.FINANCE]: "财务",
    [Label.USER]: "用户",
  },
  [Language.de]: {
    [Label.HOME]: "Startseite",
    [Label.MARKET]: "Markt",
    [Label.TRANSACTION]: "Transaktion",
    [Label.FINANCE]: "Finanzen",
    [Label.USER]: "Benutzer",
  },
  [Language.fr]: {
    [Label.HOME]: "Accueil",
    [Label.MARKET]: "Marché",
    [Label.TRANSACTION]: "Transaction",
    [Label.FINANCE]: "Finance",
    [Label.USER]: "Utilisateur",
  },
  [Language.ko]: {
    [Label.HOME]: "홈",
    [Label.MARKET]: "시장",
    [Label.TRANSACTION]: "거래",
    [Label.FINANCE]: "재정",
    [Label.USER]: "사용자",
  },
  [Language.ja]: {
    [Label.HOME]: "ホーム",
    [Label.MARKET]: "マーケット",
    [Label.TRANSACTION]: "取引",
    [Label.FINANCE]: "財務",
    [Label.USER]: "ユーザー",
  },
};

export function getTranslatedLabel(label: Label, language: Language): string {
  return LabelTranslate[language]?.[label] ?? label;
}

export function getLanguageKeyForChart(language: Language | string): string {
  switch (language) {
    case Language.vi:
      return "vi_VN";
    case Language.en:
      return "en";
    case Language.zh:
      return "zh_CN";
    case Language.ko:
      return "kr";
    case Language.ja:
      return "ja";
    case Language.fr:
      return "fr";
    case Language.de:
      return "de_DE";
    default:
      return "vi_VN";
  }
}

export const Menu: readonly MenuItem[] = [
  {
    linkTo: PathnameUrl.HOME,
    icon: Images.Home2.default,
    label: Label.HOME,
  },
  {
    linkTo: PathnameUrl.MARKET,
    icon: Images.Market2.default,
    label: Label.MARKET,
  },
  {
    linkTo: PathnameUrl.TRANSACTION,
    icon: Images.Transaction2.default,
    label: Label.TRANSACTION,
  },
  {
    linkTo: PathnameUrl.FINANCE,
    icon: Images.Finace2.default,
    label: Label.FINANCE,
  },
  {
    linkTo: PathnameUrl.USER,
    icon: Images.User2.default,
    label: Label.USER,
  },
];
