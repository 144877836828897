export enum RechargeState {
  PENDING = "pending",
  SUCCESSFULLY = "success",
  CANCELLED = "cancel",
  TIMEOUT = "time_out",
}

export const RechargeStateName = {
  [RechargeState.PENDING]: "Chờ xử lý",
  [RechargeState.SUCCESSFULLY]: "Thành công",
  [RechargeState.CANCELLED]: "Đã hủy",
  [RechargeState.TIMEOUT]: "Hết thời gian",
};
