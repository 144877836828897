import { Role } from "@app/constants";
import { AppAction } from "@core/types/redux.type";
import { MyInfoActionType, MyInfoEpicType, MyInfoState } from "./my-info.type";

export default function myInfoReducer(
  state = initialState,
  action: AppAction
): MyInfoState {
  switch (action.type) {
    case MyInfoEpicType.FETCH_MY_INFO:
      return {
        ...state,
        isMyInfoLoading: true,
        isMyInfoError: false,
      };
    case MyInfoActionType.STORE_MY_INFO:
      return {
        ...state,
        myInfo: {
          _id: action.payload?.myInfo?._id,
          uid: action.payload?.myInfo?.uid,
          phone: action.payload?.myInfo?.fullName,
          password: action.payload?.myInfo?.password,
          nick_name: action.payload?.myInfo?.nick_name,
          avt: action.payload?.myInfo?.avt,
          balance: action.payload?.myInfo?.balance ?? 0,
          balance_wallet: action.payload?.myInfo?.balance_wallet ?? 0,
          is_kyc: action.payload?.myInfo?.is_kyc,
          front_card: action.payload?.myInfo?.front_card,
          back_card: action.payload?.myInfo?.back_card,
          bet_state: action.payload?.myInfo?.bet_state,
          bank_name: action.payload?.myInfo?.bank_name,
          bank_number: action.payload?.myInfo?.bank_number,
          bank_owner: action.payload?.myInfo?.bank_owner,
          password_withdraw: action.payload?.myInfo?.password_withdraw,
          role: action.payload?.myInfo?.role,
          createdAt: action.payload?.myInfo?.createdAt,
          updatedAt: action.payload?.myInfo?.updatedAt,
        },
        isMyInfoLoading: false,
        isMyInfoError: false,
      };
    case MyInfoActionType.CLEAR_MY_INFO:
      return {
        ...initialState,
        isMyInfoLoading: false,
      };
    case MyInfoActionType.FETCH_MY_INFO_FAILED:
      return {
        ...state,
        isMyInfoLoading: false,
        isMyInfoError: true,
      };
    default:
      return state;
  }
}

const initialState: MyInfoState = {
  myInfo: {
    _id: "",
    uid: "",
    phone: "",
    password: "",
    balance: 0,
    balance_wallet: 0,
    nick_name: "",
    avt: "https://public.bnbstatic.com/20190405/eb2349c3-b2f8-4a93-a286-8f86a62ea9d8.png",
    is_kyc: false,
    role: Role.USER,
    front_card: "",
    back_card: "",
    bet_state: "",
    bank_name: "",
    bank_number: "",
    bank_owner: "",
    password_withdraw: "",
    createdAt: "",
    updatedAt: "",
  },
  isMyInfoLoading: true,
  isMyInfoError: false,
};
