import CryptocurrencyMarket from "@app/components/charts/cryptocurrency.market.component";
// import Screener from "@app/components/charts/screener.component";
// import React from "react";

function Market() {
  return (
    <div className="fade-in bg-[#181a20] h-full">
      {/* <Screener /> */}
      <CryptocurrencyMarket />
    </div>
  );
}

export default Market;
