export const BuyValue = {
  BUY_UP: "up",
  BUY_DOWN: "down",
};

export enum TimeOut {
  S30 = 30,
  S60 = 60,
  S120 = 120,
  S300 = 300,
}

export const BonusOnTimeOut = {
  [TimeOut.S30]: 10,
  [TimeOut.S60]: 20,
  [TimeOut.S120]: 30,
  [TimeOut.S300]: 40,
};
