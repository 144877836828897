import { useEffect, useState } from "react";
import useObservable from "@core/hooks/use-observable.hook";
import AccountService from "@app/services/http/account.service";
import { Account } from "@app/types";
import { Images } from "@assets/images";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUser } from "@app/store/auth/auth.action";
import StorageService from "@core/services/storage";
import { ACCESS_TOKEN_KEY, LANGUAGE_KEY, PathnameUrl, USER_INFO_KEY } from "@app/constants";
import HeaderProfile from "@app/components/header-profile";
import { addToast } from "@app/components/toast/toast.service";
import { openPortalDialog } from "@app/services/modal.service";
import User_NameModal from "@app/components/user-name-modal/user-name-modal.component";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import ChangeLanguageModal from "@app/components/change-language-modal";
import { useTranslation } from "react-i18next";

function User() {
  const [myInfo, setMyInfo] = useState<Account>();
  const { subscribeOnce } = useObservable();

  const [update, forceUpdate] = useForceUpdate();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const language = StorageService.get(LANGUAGE_KEY);

    language && i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    subscribeOnce(AccountService.getMyInfo(), (user) => {
      setMyInfo(user);
    });
  }, [update]);

  const onLogoutHandle = () => {
    dispatch(clearUser());
    StorageService.remove(ACCESS_TOKEN_KEY);
    StorageService.remove(USER_INFO_KEY);
    navigate("/login");
  };

  const onClickHistory = () => {
    navigate(PathnameUrl.LOG);
  };

  const onClickBankInfo = () => {
    navigate(PathnameUrl.BANK_INFO);
  };

  // const onClickPasswordWithdraw = () => {
  //   navigate(PathnameUrl.PASSWORD_WITHDRAW);
  // };

  const onClickChangePassword = () => {
    navigate(PathnameUrl.CHANGE_PASSWORD);
  };

  const onClickIdentityAccount = () => {
    navigate(PathnameUrl.IDENTITY_ACCOUNT);
  };

  const onClickUserName = () => {
    const updateUserNameModalObs = openPortalDialog(User_NameModal, {
      nick_name: myInfo?.nick_name,
      full_name: myInfo?.full_name,
    });

    updateUserNameModalObs.afterClosed().subscribe((data) => {
      if (data.isAccept) {
        forceUpdate();
      }
    });
  };

  const onClickLanguage = () => {
    const changeLanguageModalObs = openPortalDialog(ChangeLanguageModal);

    changeLanguageModalObs.afterClosed().subscribe((data) => {
      if (data.language) {
        navigate(0);
      }
    });
  };

  return (
    <div className="fade-in bg-[#181a20] p-4 text-[#EAECEF] pb-[64px]">
      <h1 className="font-bold text-[#F0B90B] text-[20px]">
        {translate("user.title_file")}
      </h1>
      {/* avt và tt tk */}
      {myInfo && <HeaderProfile myInfo={myInfo} />}
      {/* các chức năng */}
      <div className="w-[95%] mx-auto mt-6 p-2">
        <div className="border-b-2 border-gray-400 text-[20px]">
          {translate("user.title_file")}
        </div>
        <div className="mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg">
          <div className="flex justify-between">
            <div>UID</div>
            <div className="flex items-center gap-2">
              {myInfo?.uid}
              <i
                className="fa-regular fa-copy cursor-pointer text-white"
                onClick={() =>
                  myInfo?.uid &&
                  navigator.clipboard
                    .writeText(myInfo?.uid)
                    .then(() => addToast({ text: "copied" }))
                }
              ></i>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <div>{translate("user.label_user")}</div>
            <div className="flex items-center gap-2">
              {myInfo?.nick_name}
              <i
                className="fa-solid fa-chevron-right text-white cursor-pointer"
                onClick={onClickUserName}
              ></i>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <div>{translate("user.label_kyc")}</div>
            <div className="flex items-center gap-2">
              {myInfo?.is_kyc ? (
                <div className="flex gap-2 items-center">
                  <i
                    style={{ color: "green" }}
                    className="fa-solid fa-circle-dot"
                  ></i>
                  {translate("user.label_kyc_status_ok")}
                </div>
              ) : (
                <div className="flex gap-2 items-center">
                  <i
                    style={{ color: "red" }}
                    className="fa-solid fa-circle-dot"
                  ></i>
                  {translate("user.label_kyc_status_error")}
                  <i
                    className="fa-solid fa-chevron-right"
                    style={{ color: "#ffffff", cursor: "pointer" }}
                    onClick={onClickIdentityAccount}
                  ></i>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-6 border-b-2 border-gray-400 text-[20px]">
          {translate("user.title_finance")}
        </div>
        <div className="mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg">
          <div
            onClick={onClickBankInfo}
            className="flex cursor-pointer items-center mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg"
          >
            <img className="w-6" src={Images.Bank.default} alt="" />
            {translate("user.label_bank_account")}
          </div>
          <div
            onClick={onClickHistory}
            className="flex cursor-pointer items-center mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg"
          >
            <img className="w-6" src={Images.History.default} alt="" />
            {translate("user.label_history_change")}
          </div>
          <div
            className="flex cursor-pointer items-center mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg"
            onClick={() => navigate(PathnameUrl.EXCHANGE_MONEY)}
          >
            <img className="w-6" src={Images.ExchangeRate.default} alt="" />
            {translate("user.label_exchange_money")}
          </div>
        </div>
        <div className="mt-6 border-b-2 border-gray-400 text-[20px]">
          {translate("user.title_security")}
        </div>
        <div className="mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg">
          <div
            onClick={onClickChangePassword}
            className="flex cursor-pointer items-center mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg"
          >
            <img className="w-6" src={Images.Padlock.default} alt="" />
            {translate("user.label_change_password")}
          </div>
        </div>

        {/* <div
          onClick={onClickPasswordWithdraw}
          className="flex cursor-pointer items-center mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg"
        >
          <img className="w-6" src={Images.MoneySack.default} alt="" />
          Mật Khẩu Rút Tiền
        </div> */}

        <div className="mt-6 border-b-2 border-gray-400 text-[20px]">
          {translate("user.title_support")}
        </div>
        <div className="mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg">
          <div
            className="flex cursor-pointer items-center mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg"
            onClick={() => navigate(PathnameUrl.CHAT)}
          >
            <img className="w-6" src={Images.Messenger.default} alt="" />
            {translate("user.label_support_online")}
          </div>

          <div
            className="flex cursor-pointer items-center mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg"
            onClick={onClickLanguage}
          >
            <img className="w-6" src={Images.World.default} alt="" />
            {translate("user.label_language")}
          </div>
          <div
            className="flex cursor-pointer items-center mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg"
            onClick={() => navigate(PathnameUrl.ABOUTS)}
          >
            <img className="w-6" src={Images.Info.default} alt="" />
            {translate("user.label_about")}
          </div>
        </div>
        <div
          onClick={onLogoutHandle}
          className="flex cursor-pointer text-red-500 items-center mt-2 p-4 gap-2 bg-[#1e2329] rounded-lg"
        >
          <img className="w-6" src={Images.Out.default} alt="" />
          {translate("user.label_logout")}
        </div>
      </div>
    </div>
  );
}

export default User;
