import HeaderProfile from "@app/components/header-profile";
import { addToast } from "@app/components/toast/toast.service";
import { LANGUAGE_KEY, PathnameUrl } from "@app/constants";
import AccountService from "@app/services/http/account.service";
import WithdrawService from "@app/services/http/withdraw.service";
import { Account, ExchangeMoneyToWalletAccount } from "@app/types";
import { WithdrawInitialValues } from "@app/types/withdraw.type";
import { formatMoneyUSDT } from "@app/utils/util";
import { Images } from "@assets/images";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import StorageService from "@core/services/storage";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function ExchangeMoney() {
  const [amountToExchange, setAmountToExchange] = useState("");
  const [amountToWithdraw, setAmountToWithdraw] = useState("");
  const [myInfo, setMyInfo] = useState<Account>();
  const { subscribeOnce } = useObservable();

  const navigate = useNavigate();
  const [update, forceUpdate] = useForceUpdate();

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const language = StorageService.get(LANGUAGE_KEY);

    language && i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    subscribeOnce(AccountService.getMyInfo(), (user) => {
      setMyInfo(user);
    });
  }, [update]);

  const handleExchangeMoney = () => {
    if (amountToExchange && +amountToExchange > 0) {
      // Call API or perform exchange logic here
      const exchangeMoney: ExchangeMoneyToWalletAccount = {
        money: +amountToExchange,
      };

      subscribeOnce(AccountService.exchangeMoneyToWallet(exchangeMoney), () => {
        addToast({ text: "Success" });
        setAmountToExchange("");
        forceUpdate();
      });
    } else {
      addToast({
        text: translate("user.exchange_money.label_error_withdraw"),
        status: "inValid",
      });
    }
  };

  const handleWithdrawMoney = () => {
    if (
      amountToWithdraw &&
      +amountToWithdraw > 0 &&
      myInfo?.bank_name &&
      myInfo?.bank_number &&
      myInfo?.bank_owner
    ) {
      // Call API or perform withdraw logic here
      const withdrawRequest: WithdrawInitialValues = {
        money: +amountToWithdraw,
      };

      subscribeOnce(WithdrawService.createWithdraw(withdrawRequest), () => {
        addToast({
          text: translate("user.exchange_money.success_w"),
        });
        setAmountToWithdraw("");
        forceUpdate();
      });
    } else if (
      !myInfo?.bank_name ||
      !myInfo?.bank_number ||
      !myInfo?.bank_owner
    ) {
      addToast({
        text: translate("user.exchange_money.errror_need_bank"),
        status: "inValid",
      });
    } else {
      addToast({
        text: translate("user.exchange_money.label_error_withdraw"),
        status: "inValid",
      });
    }
  };

  return (
    <div className="bg-[#181a20] min-h-screen flex flex-col items-center p-6">
      <div className="flex justify-between w-full mb-4">
        <i
          onClick={() => navigate(-1)}
          className="fa-solid fa-chevron-left text-white"
        ></i>
        {/* <i className="fa-solid fa-circle-question text-white"></i> */}
      </div>
      {myInfo && (
        <div>
          <div className="bg-[#1e2329] rounded-lg shadow-md p-8 max-w-lg w-full text-white">
            {/* avt và tt tk */}
            {myInfo && <HeaderProfile myInfo={myInfo} />}
            {/* Title */}
            <div className="flex justify-between mt-6">
              <div className="text-center">
                {translate("user.exchange_money.title1")}
                <div className="text-yellow-500">
                  {formatMoneyUSDT(myInfo.balance || 0)} USDT
                </div>
              </div>
              <div className="text-center">
                {translate("user.exchange_money.title2")}
                <div className="text-yellow-500">
                  {formatMoneyUSDT(myInfo.balance_wallet || 0)} USDT
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#1e2329] rounded-lg shadow-md p-8 my-6 max-w-lg w-full text-white">
            {/* Chuyển tiền từ Tài khoản giao ngay vào Ví tài khoản */}
            <h1 className="text-[20px] font-bold text-white mb-4 mt-4">
              {translate("user.exchange_money.title_exchange")}
            </h1>
            <div className="mb-6">
              <div className="flex gap-2 items-center justify-between text-lg font-semibold text-gray-300 my-4">
                {translate("user.exchange_money.title_e1")}
                <img className="w-10" src={Images.Transfer.default} alt="" />
                {translate("user.exchange_money.title_e2")}
              </div>
              <input
                type="text"
                className="border border-gray-700 bg-[#1e2329] text-white rounded-lg w-full p-2 mb-2 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                placeholder={translate("user.exchange_money.text_input")}
                value={amountToExchange}
                onChange={(e) => setAmountToExchange(e.target.value)}
              />
              <div className="mb-2">
                {+amountToExchange && +amountToExchange > 0 ? (
                  <div className="text-green-500 text-[12px]">
                    {translate("user.exchange_money.money_to_wallet")}:{" "}
                    {formatMoneyUSDT(amountToExchange)} usdt
                  </div>
                ) : (
                  <div className="text-[12px] text-red-500">
                    {translate("user.exchange_money.label_error_exchange")}
                  </div>
                )}
              </div>
              <button
                className="w-full bg-yellow-500 text-white py-2 rounded-lg font-semibold hover:bg-[#181a20] transition-colors"
                onClick={handleExchangeMoney}
              >
                {translate("user.exchange_money.btn_exchange")}
              </button>
            </div>
          </div>

          <div className="bg-[#1e2329] rounded-lg shadow-md p-8 max-w-lg w-full text-white">
            {/* Rút tiền từ Ví */}
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-300 my-4">
                {translate("user.exchange_money.title_withdraw")}
              </h2>
              <input
                type="text"
                className="border border-gray-700 bg-[#1e2329] text-white rounded-lg w-full p-2 mb-2 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                placeholder={translate("user.exchange_money.text_input2")}
                value={amountToWithdraw}
                onChange={(e) => setAmountToWithdraw(e.target.value)}
              />
              <div>
                {+amountToWithdraw && +amountToWithdraw > 0 ? (
                  <div className="text-green-500 text-[12px]">
                    {translate("user.exchange_money.label_money_from_wallet")}:{" "}
                    {formatMoneyUSDT(amountToWithdraw)} usdt
                  </div>
                ) : (
                  <div className="text-[12px] text-red-500">
                    {translate("user.exchange_money.label_error_withdraw")}
                  </div>
                )}
              </div>

              {/* Thông tin tài khoản ngân hàng */}
              <div className="bg-[#2a2d34] p-4 rounded-lg shadow-md mb-2 mt-4">
                <div className="flex justify-between text-gray-400">
                  <div>{translate("user.exchange_money.label_bank_name")}:</div>{" "}
                  {myInfo?.bank_name || (
                    <div className="flex items-center gap-2">
                      {translate("user.exchange_money.label_update_now")}
                      <i
                        onClick={() => navigate(PathnameUrl.BANK_INFO)}
                        className="fa-solid fa-chevron-right text-white cursor-pointer"
                      ></i>
                    </div>
                  )}
                </div>
                <div className="flex justify-between text-gray-400">
                  <div>
                    {translate("user.exchange_money.label_bank_number")}:
                  </div>{" "}
                  {myInfo?.bank_number || (
                    <div className="flex items-center gap-2">
                      {translate("user.exchange_money.label_update_now")}
                      <i
                        onClick={() => navigate(PathnameUrl.BANK_INFO)}
                        className="fa-solid fa-chevron-right text-white cursor-pointer"
                      ></i>
                    </div>
                  )}
                </div>
                <div className="flex justify-between text-gray-400">
                  <div>
                    {translate("user.exchange_money.label_bank_owner")}:
                  </div>{" "}
                  {myInfo?.bank_owner || (
                    <div className="flex items-center gap-2">
                      {translate("user.exchange_money.label_update_now")}
                      <i
                        onClick={() => navigate(PathnameUrl.BANK_INFO)}
                        className="fa-solid fa-chevron-right text-white cursor-pointer"
                      ></i>
                    </div>
                  )}
                </div>
              </div>

              <button
                className="w-full bg-yellow-500 text-white py-2 rounded-lg font-semibold hover:bg-[#181a20] transition-colors"
                onClick={handleWithdrawMoney}
              >
                {translate("user.exchange_money.btn_withdraw_now")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExchangeMoney;
