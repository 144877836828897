import { getLanguageKeyForChart, Language, LANGUAGE_KEY } from "@app/constants";
import StorageService from "@core/services/storage";
import React, { useEffect, useRef } from "react";

const TopStories: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const l = StorageService.get(LANGUAGE_KEY);

    const language = getLanguageKeyForChart(l as Language);

    // Tạo script mới
    const script = document.createElement("script");

    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-timeline.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      feedMode: "market",
      market: "crypto",
      isTransparent: false,
      displayMode: "adaptive",
      width: "100%",
      height: "1000",
      colorTheme: "dark",
      locale: language,
    });

    // Thêm script vào container
    containerRef.current.appendChild(script);
  }, []);

  return (
    <div className="tradingview-widget-container relative" ref={containerRef}>
      <div className="tradingview-widget-container__widget"></div>
      <div
        style={{
          position: "absolute",
          right: "0",
          top: "0",
          width: "30px",
          height: "40px",
          // backgroundColor: "rgba(1,1,1,0.4)",
          backgroundColor: "transparent",
        }}
      ></div>
    </div>
  );
};

export default TopStories;
