import { BuyValue } from "@app/constants/transaction.const";
import clsx from "clsx";
import React, { memo, useEffect, useState } from "react";
import Input from "../input";
import { formatMoneyUSDT } from "@app/utils/util";
import { useTranslation } from "react-i18next";
import StorageService from "@core/services/storage";
import { LANGUAGE_KEY } from "@app/constants";

interface TransactionModalProps {
  isOpen: boolean;
  onClose: any;
  second: number;
  buyValue: string;
  priceClose: number;
  onConfirm: (data: any) => void;
}

const TransactionModal: React.FC<TransactionModalProps> = ({
  isOpen,
  onClose,
  second,
  buyValue,
  priceClose,
  onConfirm,
}) => {
  if (!isOpen) return null; // Don't render if not open

  const [secondChoice, setSecondChoice] = useState("30s");
  const [betMoney, setBetMoney] = useState(0);

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const language = StorageService.get(LANGUAGE_KEY);

    language && i18n.changeLanguage(language);
  }, []);

  const handleConfirm = () => {
    onConfirm({ betMoney, secondChoice });
  };

  return (
    <div className="fade-in-dialog fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-20">
      <div className="bg-white p-6 rounded-md shadow-lg w-11/12 md:w-3/4 lg:w-1/2">
        <h2
          className={clsx(
            `${
              buyValue === BuyValue.BUY_UP ? "text-[#089981]" : "text-[#f23645]"
            } text-xl font-bold mb-4`
          )}
        >
          {buyValue === BuyValue.BUY_DOWN
            ? translate("transaction.btn_buy_down")
            : translate("transaction.btn_buy_up")}
        </h2>
        {/* tên giao dịch  */}
        <div className="flex justify-between">
          <div className="text-gray-500">
            {translate("transaction.label_name")}
          </div>
          <div className="font-bold">BTC/USDT</div>
        </div>
        {/* giá hiện tại  */}
        <div className="flex justify-between border-t-[1px] border-gray-500 mt-2 pt-2">
          <div className="text-gray-500">
            {translate("transaction.label_now_price")}
          </div>
          <div className="font-bold">{priceClose}</div>
        </div>
        {/* thời gian hết hạn  */}
        <div className="flex flex-col justify-between border-t-[1px] border-gray-500 mt-2 pt-2">
          <div className="text-gray-500">
            {translate("transaction.label_choice_time_out")}
          </div>
          <div className="flex justify-between">
            {/* 30s */}
            <div
              className={clsx(
                `w-[23%] cursor-pointer p-1 rounded-lg text-center border-[1px] border-${
                  buyValue === BuyValue.BUY_UP ? "[#089981]" : "[#f23645]"
                } text-${
                  secondChoice === "30s"
                    ? "white"
                    : buyValue === BuyValue.BUY_UP
                    ? "[#089981]"
                    : "[#f23645]"
                } bg-${
                  secondChoice === "30s"
                    ? buyValue === BuyValue.BUY_UP
                      ? "[#089981]"
                      : "[#f23645]"
                    : "none"
                }`
              )}
              onClick={() => setSecondChoice("30s")}
            >
              30s
            </div>
            {/* 60s */}
            <div
              className={clsx(
                `w-[23%] cursor-pointer p-1 rounded-lg text-center border-[1px] border-${
                  buyValue === BuyValue.BUY_UP ? "[#089981]" : "[#f23645]"
                } text-${
                  secondChoice === "60s"
                    ? "white"
                    : buyValue === BuyValue.BUY_UP
                    ? "[#089981]"
                    : "[#f23645]"
                } bg-${
                  secondChoice === "60s"
                    ? buyValue === BuyValue.BUY_UP
                      ? "[#089981]"
                      : "[#f23645]"
                    : "none"
                }`
              )}
              onClick={() => setSecondChoice("60s")}
            >
              60s
            </div>
            {/* 120s */}
            <div
              className={clsx(
                `w-[23%] cursor-pointer p-1 rounded-lg text-center border-[1px] border-${
                  buyValue === BuyValue.BUY_UP ? "[#089981]" : "[#f23645]"
                } text-${
                  secondChoice === "120s"
                    ? "white"
                    : buyValue === BuyValue.BUY_UP
                    ? "[#089981]"
                    : "[#f23645]"
                } bg-${
                  secondChoice === "120s"
                    ? buyValue === BuyValue.BUY_UP
                      ? "[#089981]"
                      : "[#f23645]"
                    : "none"
                }`
              )}
              onClick={() => setSecondChoice("120s")}
            >
              120s
            </div>
            {/* 300s */}
            <div
              className={clsx(
                `w-[23%] cursor-pointer p-1 rounded-lg text-center border-[1px] border-${
                  buyValue === BuyValue.BUY_UP ? "[#089981]" : "[#f23645]"
                } text-${
                  secondChoice === "300s"
                    ? "white"
                    : buyValue === BuyValue.BUY_UP
                    ? "[#089981]"
                    : "[#f23645]"
                } bg-${
                  secondChoice === "300s"
                    ? buyValue === BuyValue.BUY_UP
                      ? "[#089981]"
                      : "[#f23645]"
                    : "none"
                }`
              )}
              onClick={() => setSecondChoice("300s")}
            >
              300s
            </div>
          </div>
        </div>
        {/* số lượng giao dịch */}
        <div className="flex flex-col justify-between border-t-[1px] border-gray-500 mt-2 pt-2">
          <div className="text-gray-500">
            {translate("transaction.label_bet_value")}
          </div>
          <Input
            className="border-gray-500"
            onChange={(e) => setBetMoney(+e.target.value)}
          />
          {betMoney ? (
            <div className="text-[13px] mt-1 text-green-500">
              {formatMoneyUSDT(betMoney)}
            </div>
          ) : (
            <div className="text-red-500 text-[11px] mt-1">
              {translate("transaction.label_error")}
            </div>
          )}
          <div className="text-[11px] text-gray-500 my-2">
            {translate("transaction.label_balance")}: 195006.7000 USDT {second}
          </div>
        </div>
        {/* hủy / xác nhận  */}
        <div className="flex justify-around text-white">
          <div
            className="w-[45%] text-center p-2 rounded-lg bg-gray-500 cursor-pointer"
            onClick={onClose}
          >
            {translate("btn.btn_cancel")}
          </div>
          <div
            className={clsx(
              `${
                buyValue === BuyValue.BUY_UP ? "bg-[#089981]" : "bg-[#f23645]"
              } w-[45%] text-center p-2 rounded-lg cursor-pointer`
            )}
            onClick={handleConfirm}
          >
            {translate("btn.btn_confirm")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TransactionModal);
