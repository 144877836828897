import MarketOverview from "@app/components/charts/market.overview.component";
import TechnicalAnalysis from "@app/components/charts/technical.analysis";
// import TradingViewTickerTape from "@app/components/charts/ticker.tape.component";
import AccountService from "@app/services/http/account.service";
import { Account } from "@app/types";
import { formatMoneyUSDT } from "@app/utils/util";
import { Images } from "@assets/images";
import useObservable from "@core/hooks/use-observable.hook";
import { openPortalDialog } from "@app/services/modal.service";
import CreateRechargeModal from "@app/components/recharge-modal";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import MiniChartComponent from "@app/components/charts/mini.chart.component";
import { LANGUAGE_KEY, PathnameUrl } from "@app/constants";
import { useTranslation } from "react-i18next";
import StorageService from "@core/services/storage";

function Home() {
  const [myInfo, setMyInfo] = useState<Account>();
  const [update, forceUpdate] = useForceUpdate();

  const navigate = useNavigate();

  const { subscribeOnce } = useObservable();

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const l = StorageService.get(LANGUAGE_KEY);

    if (l) {
      i18n.changeLanguage(l);
    }
  }, []);

  useEffect(() => {
    subscribeOnce(AccountService.getMyInfo(), (user) => {
      setMyInfo(user);
    });
  }, [update]);

  const handleRecharge = () => {
    const createRechargeModalObs = openPortalDialog(CreateRechargeModal);

    createRechargeModalObs.afterClosed().subscribe(() => {
      forceUpdate();
    });
  };

  return (
    <div className="fade-in bg-[#181a20] px-4">
      {/* header */}
      <div className="flex justify-between items-center pt-1">
        <div className="flex items-center gap-2">
          <img
            className="w-10 rounded-[50%]"
            src={Images.Logo2.default}
            alt=""
            onClick={() => navigate(0)}
          />
          <div className="text-white flex flex-col text-[12px]">
            <p>{myInfo?.phone}</p>
            <p>{myInfo?.nick_name}</p>
          </div>
        </div>
        <div className="text-[32px] text-[#00e0ff] font-extrabold">
          <span>Bit</span>
          <span>get</span>
        </div>
        <img
          className="w-6 cursor-pointer"
          src={Images.User2.default}
          alt=""
          onClick={() => navigate(PathnameUrl.USER)}
        />
      </div>

      {/* ô xám */}
      <div className="bg-[#1e2329] w-[96%] mx-auto p-4 my-3 min-h-8 rounded-lg">
        {/* tổng tài sản + số dư  */}
        <div>
          <div className="text-[#EAECEF] text-[12px] flex items-center gap-2">
            {translate("home.total_money")}
            {/* Tổng Tài Sản (USDT) */}
          </div>
          <div className="text-[#F0B90B] text-[32px]">
            {formatMoneyUSDT(myInfo?.balance || 0)}
          </div>
        </div>
        {/* dòng 1 */}
        <div className="flex justify-evenly mt-4">
          <div
            className="text-[#EAECEF] gap-1 flex flex-col justify-center items-center"
            onClick={handleRecharge}
          >
            <div className="bg-[#323942] w-fit p-2 rounded-[50%]">
              <img className="w-6" src={Images.RechargeYellow.default} alt="" />
            </div>
            <p>{translate("home.btn_recharge")}</p>
          </div>
          <div
            className="text-[#EAECEF] gap-1 flex flex-col justify-center items-center"
            onClick={() => navigate(PathnameUrl.EXCHANGE_MONEY)}
          >
            <div className="bg-[#323942] w-fit p-2 rounded-[50%]">
              <img className="w-6" src={Images.MoneyYellow.default} alt="" />
            </div>
            <p>{translate("home.btn_withdraw")}</p>
          </div>
          <div
            className="text-[#EAECEF] gap-1 flex flex-col justify-center items-center cursor-pointer"
            onClick={() => navigate("/logs")}
          >
            <div className="bg-[#323942] w-fit p-2 rounded-[50%]">
              <img className="w-6" src={Images.History.default} alt="" />
            </div>
            <p>{translate("home.btn_history")}</p>
          </div>
          <div
            className="text-[#EAECEF] gap-1 flex flex-col justify-center items-center"
            onClick={() => navigate(PathnameUrl.CHAT)}
          >
            <div className="bg-[#323942] w-fit p-2 rounded-[50%]">
              <img className="w-6" src={Images.Messenger.default} alt="" />
            </div>
            <p>{translate("home.btn_help")}</p>
          </div>
        </div>
      </div>
      <div className="h-4"></div>
      {/* <TradingViewTickerTape /> */}
      <MiniChartComponent />
      <div className="h-4"></div>
      <div className="min-h-52">
        <TechnicalAnalysis />
      </div>
      <div className="h-4"></div>
      <MarketOverview />
      <div className="h-20"></div>
    </div>
  );
}

export default Home;
