// TechnicalAnalysis.tsx
import {
  getLanguageKeyForChart,
  Language,
  LANGUAGE_KEY,
  PathnameUrl,
} from "@app/constants";
import StorageService from "@core/services/storage";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const TechnicalAnalysis: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    if (!containerRef.current) return;

    const l = StorageService.get(LANGUAGE_KEY);

    const language = getLanguageKeyForChart(l as Language);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const h = windowWidth < 800 ? "1200" : windowWidth > 1200 ? "550" : "850";

    // Lắng nghe sự kiện thay đổi kích thước cửa sổ
    window.addEventListener("resize", handleResize);

    const script = document.createElement("script");

    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      interval: "1m",
      width: "100%",
      isTransparent: false,
      height: h,
      symbol: "MEXC:BTCUSDT",
      showIntervalTabs: true,
      displayMode: "multiple",
      locale: language,
      colorTheme: "dark",
    });

    containerRef.current.appendChild(script);

    // Dọn dẹp sự kiện khi component bị huỷ
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    navigate(PathnameUrl.TRANSACTION); // Thay bằng URL mong muốn
  };

  return (
    <div className="tradingview-widget-container relative" ref={containerRef}>
      <div className="tradingview-widget-container__widget"></div>
      <div
        onClick={handleClick}
        style={{
          position: "absolute",
          top: 0,
          left: "20px",
          width: "90%",
          height: "65px",
          // backgroundColor: "rgba(1,1,1,0.4)",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          right: "0",
          bottom: "0",
          width: "30px",
          height: "40px",
          // backgroundColor: "rgba(1,1,1,0.4)",
          backgroundColor: "transparent",
        }}
      ></div>
    </div>
  );
};

export default TechnicalAnalysis;
