import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import Button from "../button";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { validationUpdateUserNameSchema } from "@app/validations";
import useObservable from "@core/hooks/use-observable.hook";
import AccountService from "@app/services/http/account.service";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import StorageService from "@core/services/storage";
import { LANGUAGE_KEY } from "@app/constants";

function User_NameModal({ portalDialogRef, portalData }: PortalDialogProps) {
  const { subscribeOnce } = useObservable();
  const handleSubmit = (value: { nick_name: string; full_name: string }) => {
    subscribeOnce(AccountService.updateAccount(value), () => {
      portalDialogRef.close({ isAccept: true });
    });
  };

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const language = StorageService.get(LANGUAGE_KEY);

    language && i18n.changeLanguage(language);
  }, []);

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="fade-in-dialog fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-20">
        <div className="bg-white p-6 rounded-md shadow-lg w-11/12 md:w-3/4 lg:w-1/2">
          <div className="flex justify-between items-center">
            <h2 className="text-[20px] font-bold">
              {translate("user.update_user_name.title")}
            </h2>
            <i
              className="fa-solid fa-xmark text-xl cursor-pointer"
              onClick={() => portalDialogRef.close({ isAccept: false })}
            ></i>
          </div>
          <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
            <Formik
              initialValues={{
                nick_name: portalData?.nick_name,
                full_name: portalData.full_name,
              }}
              validationSchema={validationUpdateUserNameSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  {/* Nickname field */}
                  <div className="mb-4">
                    <label
                      htmlFor="nick_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nickname
                    </label>
                    <Field
                      type="text"
                      name="nick_name"
                      id="nick_name"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <ErrorMessage
                      name="nick_name"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  {/* Full name field */}
                  <div className="mb-4">
                    <label
                      htmlFor="full_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {translate("user.update_user_name.label_full_name")}
                    </label>
                    <Field
                      type="text"
                      name="full_name"
                      id="full_name"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <ErrorMessage
                      name="full_name"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  {/* Submit button */}
                  <Button
                    type="submit"
                    size="m"
                    label={translate("user.btn.btn_update")}
                    containerClassName="mt-6"
                    width="100%"
                    className="px-6 text-sm bg-[#fcd535]"
                    labelClassName="font-bold"
                    // onClick={() => portalDialogRef.close({ isAccept: true })}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default User_NameModal;
