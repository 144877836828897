import {
  BonusOnTimeOut,
  BuyValue,
  TimeOut,
} from "@app/constants/transaction.const";
import { formatMoneyUSDT } from "@app/utils/util";
import clsx from "clsx";
import React, { memo, useEffect } from "react";
import { TransactionTimerResponse } from "@app/types";
import { useTranslation } from "react-i18next";
import StorageService from "@core/services/storage";
import { LANGUAGE_KEY } from "@app/constants";

interface TransactionResultModalProps
  extends Partial<TransactionTimerResponse> {
  isOpen: boolean;
  onClose: any;
  close: number;
}

const TransactionResultModal: React.FC<TransactionResultModalProps> = ({
  isOpen,
  onClose,
  close,
  my_close,
  counter = 0,
  name,
  buy_value,
  buy_price = 0,
  time_out,
  bet_money = 0,
  result,
}) => {
  if (!isOpen) return null; // Don't render if not open

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const language = StorageService.get(LANGUAGE_KEY);

    language && i18n.changeLanguage(language);
  }, []);

  return (
    <div className="fade-in-dialog fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-20">
      <div className="bg-white p-6 rounded-md shadow-lg w-11/12 md:w-3/4 lg:w-1/2">
        {result && bet_money ? (
          <div
            className={clsx(
              `${
                result === "win" ? "bg-[#089981]" : "bg-[#f23645]"
              } text-[17px] font-bold mb-4 mx-auto w-[80px] h-[80px] leading-[80px] rounded-[50%] text-center text-white`
            )}
          >
            {result === "win" ? "+" : "-"} {formatMoneyUSDT(+bet_money)}
          </div>
        ) : (
          <div
            className={clsx(
              `${
                buy_value === BuyValue.BUY_UP ? "bg-[#089981]" : "bg-[#f23645]"
              } text-[26px] font-bold mb-4 mx-auto w-[80px] h-[80px] leading-[80px] rounded-[50%] text-center text-white`
            )}
          >
            {counter}
          </div>
        )}
        {/* tên giao dịch  */}
        <div className="flex justify-between">
          <div className="text-gray-500">
            {translate("transaction.label_name")}
          </div>
          <div className="">{name}</div>
        </div>
        {/* phương hướng  */}
        <div className="flex justify-between border-t-[1px] border-gray-500 mt-2 pt-2">
          <div className="text-gray-500">
            {translate("transaction.label_buy_value")}
          </div>
          <div
            className={clsx(
              `${
                buy_value === BuyValue.BUY_UP
                  ? "text-[#089981]"
                  : "text-[#f23645]"
              }`
            )}
          >
            {buy_value === BuyValue.BUY_UP ? "Mua lên" : "Mua xuống"}
          </div>
        </div>
        {/* giá mua  */}
        <div className="flex justify-between border-t-[1px] border-gray-500 mt-2 pt-2">
          <div className="text-gray-500">
            {translate("transaction.label_buy_price")}
          </div>
          <div className="">{buy_price}</div>
        </div>
        {/* giá hiện tại  */}
        <div className="flex justify-between border-t-[1px] border-gray-500 mt-2 pt-2">
          <div className="text-gray-500">
            {translate("transaction.label_now_price")}
          </div>
          <div className="">{my_close || close}</div>
        </div>
        {/* số luognwj giao dịch  */}
        <div className="flex justify-between border-t-[1px] border-gray-500 mt-2 pt-2">
          <div className="text-gray-500">
            {translate("transaction.label_bet_value")}
          </div>
          <div className="">{formatMoneyUSDT(bet_money)} USDT</div>
        </div>
        {/* thu nhập dự tính  */}
        <div className="flex justify-between border-t-[1px] border-gray-500 mt-2 pt-2">
          <div className="text-gray-500">
            {translate("transaction.label_est_icome")}
          </div>
          <div className="">
            {formatMoneyUSDT(
              (BonusOnTimeOut[time_out || TimeOut.S30] * bet_money) / 100
            )}{" "}
            USDT
          </div>
        </div>
        {/* hủy / xác nhận  */}
        <div className="flex justify-around text-white mt-3">
          <div
            className="w-[95%] text-center p-2 rounded-lg bg-[#151924] cursor-pointer"
            onClick={onClose}
          >
            {translate("transaction.btn_continue_transaction")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TransactionResultModal);
