import {
  Account,
  AccountPassword,
  Bank,
  BankInitValue,
  ExchangeMoneyToWalletAccount,
  UpdateAccount,
  VerifyAccountRequest,
} from "@app/types";
import HttpService from "@core/services/http/http.service";
import { RequestContentType } from "@core/services/http/http.type";

class _AccountService {
  public getMyInfo() {
    return HttpService.get<Account>("/accounts/profile");
  }

  public changePassword(accountPassword: AccountPassword) {
    return HttpService.patch<Account>("/accounts/change-password", {
      body: {
        ...accountPassword,
      },
    });
  }

  public updateMyBank(bank: BankInitValue) {
    return HttpService.patch<Bank>("/accounts/update-bank", {
      body: { ...bank },
    });
  }

  public verifyAccount(verifyAccountRequest: VerifyAccountRequest) {
    return HttpService.patch<Account>("/accounts/verify-account", {
      requestContentType: RequestContentType.MULTIPART,
      body: { ...verifyAccountRequest },
    });
  }

  public updateAccount(accountUpdate: UpdateAccount) {
    return HttpService.patch<Account>("/accounts", {
      body: {
        ...accountUpdate,
      },
    });
  }

  public exchangeMoneyToWallet(body: ExchangeMoneyToWalletAccount) {
    return HttpService.patch<Account>("/accounts/exchange-money-to-wallet", {
      body: {
        ...body,
      },
    });
  }
}

const AccountService = new _AccountService();

export default AccountService;
