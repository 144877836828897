import { WithdrawInitialValues, WithdrawResponse } from "@app/types";

import HttpService from "@core/services/http/http.service";

class _WithdrawService {
  public getWithdrawals(page: number) {
    return HttpService.get<WithdrawResponse>(`/withdrawals?page=${page}`);
  }

  public createWithdraw(createRequest: WithdrawInitialValues) {
    return HttpService.post<WithdrawInitialValues>("/withdrawals", {
      body: { ...createRequest },
    });
  }
}

const WithdrawService = new _WithdrawService();

export default WithdrawService;
