import ChartComponent from "@app/components/charts/chart.component";
import TransactionModal from "@app/components/transaction-modal";
import TransactionResultModal from "@app/components/transaction-result-modal";
import { LANGUAGE_KEY } from "@app/constants";
import { BuyValue } from "@app/constants/transaction.const";
import TransactionService from "@app/services/http/transaction.service";
import SocketService from "@app/services/socket.service";
import {
  CreateTransactionRequest,
  Transaction,
  TransactionTimerResponse,
} from "@app/types";
import { formatMoneyUSDT } from "@app/utils/util";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import StorageService from "@core/services/storage";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Subscription } from "rxjs";

function TransactionPage() {
  const [isOpenTransactionModal, setIsOpenTransactionModal] = useState(false);
  const [close, setClose] = useState(0);
  //up, down
  const [buyValue, setBuyValue] = useState("");
  //hứng kết quả socket khi countdown
  const [transactionTimerResponse, setTransactionTimerResponse] =
    useState<TransactionTimerResponse>();
  //hứng kết quả khi tạo đơn thành công
  const [transaction, setTransaction] = useState<Transaction>();
  //ẩn hiện result modal hoặc circle counter
  const [showResultModal, setShowResultModal] = useState(false);

  const { subscribeOnce } = useObservable();
  const [update, forceUpdate] = useForceUpdate();

  const [translate, i18n] = useTranslation("global");

  //ngoon nguw
  useEffect(() => {
    const language = StorageService.get(LANGUAGE_KEY);

    language && i18n.changeLanguage(language);
  }, []);

  //nếu không nhận được kline thì coonect lại
  useEffect(() => {
    setTimeout(() => {
      if (!close || (transaction && !transactionTimerResponse)) {
        forceUpdate();
      }
    }, 2000);
  });

  //on kline
  useEffect(() => {
    const subscription = new Subscription();
    const sub = SocketService.onKline().subscribe((data) => {
      setClose(data.close);
    });

    subscription.add(sub);

    return () => subscription.unsubscribe();
  }, [update]);

  //on result transaction
  useEffect(() => {
    const subscription = new Subscription();

    if (transaction) {
      const subTimber = SocketService.onTransactionTimer(
        transaction?._id.toString() || ""
      ).subscribe((data) => {
        setTransactionTimerResponse(data);
      });

      subscription.add(subTimber);
    }

    return () => {
      subscription.unsubscribe();
    };
  }, [transaction, update]);

  // useEffect(() => () => subscription.unsubscribe(), [subscription]);

  const onClickBuy = (value: string) => {
    setBuyValue(value);
    setIsOpenTransactionModal(true);
  };

  const onClose = () => {
    setIsOpenTransactionModal(false);
  };

  const onConfirm = (data: any) => {
    const createTransactionRequest: CreateTransactionRequest = {
      name: "BTC/USDT",
      buy_value: buyValue,
      buy_price: close,
      time_out: +data.secondChoice.slice(0, -1),
      bet_money: +data.betMoney,
    };

    subscribeOnce(
      TransactionService.createTransaction(createTransactionRequest),
      (data) => {
        setTransaction(data);
        setTransactionTimerResponse(undefined);
        setShowResultModal(true);
        onClose();
      }
    );
  };

  //xử lý TransactionResultModal
  const onCloseResultModal = () => {
    setShowResultModal(false);
  };

  return (
    <div className="fade-in bg-[#151924] h-[100vh] pb-[108px]">
      <ChartComponent />
      {/* mua lên / mua xuống  */}
      <div className="flex justify-center gap-4 text-center text-white w-[100%] fixed bottom-[67px]">
        <div
          className="flex flex-col justify-center items-center bg-[#089981] w-[45%] p-1 box-content rounded-lg cursor-pointer"
          onClick={() => onClickBuy(BuyValue.BUY_UP)}
        >
          {/* <div> */}
          <div>{translate("transaction.btn_buy_up")}</div>
          <div className="text-[10px]">{close}</div>
          {/* </div> */}
          {/* <i className="fa-solid fa-arrow-trend-up" style={{ color: "#FFD43B", fontSize: 20 }}></i> */}
        </div>
        <div
          className="flex flex-col bg-[#f23645] w-[45%] p-1 box-content rounded-lg cursor-pointer"
          onClick={() => onClickBuy(BuyValue.BUY_DOWN)}
        >
          <div>{translate("transaction.btn_buy_down")}</div>
          <div className="text-[10px]">{close}</div>
        </div>
      </div>
      {/* hình tròn đếm  */}
      {transactionTimerResponse && +transactionTimerResponse?.counter > 0 && (
        <div
          className={clsx(
            `fixed cursor-pointer bottom-[150px] right-[20px] text-white w-[80px] h-[80px] z-10 rounded-[50%] flex flex-col justify-center items-center ${
              transactionTimerResponse.buy_value === BuyValue.BUY_UP
                ? "bg-[#089981]"
                : "bg-[#f23645]"
            } ${showResultModal ? "hidden" : ""}`
          )}
          onClick={() => setShowResultModal(true)}
        >
          <div className="text-[12px]">{transactionTimerResponse.counter}s</div>
          <div className="text-[14px]">
            {formatMoneyUSDT(+transactionTimerResponse.bet_money)}
          </div>
        </div>
      )}

      <TransactionModal
        isOpen={isOpenTransactionModal}
        onClose={onClose}
        onConfirm={onConfirm}
        second={transactionTimerResponse?.counter || 0}
        priceClose={close}
        buyValue={buyValue}
      />
      <TransactionResultModal
        isOpen={showResultModal}
        onClose={onCloseResultModal}
        close={close}
        {...transactionTimerResponse}
      />
    </div>
  );
}

export default TransactionPage;
