import { DEFAULT_PAGE } from "@app/constants";
import { MessageResponse } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _MessageService {
  public getMessages(page = DEFAULT_PAGE) {
    return HttpService.get<MessageResponse>("/messages", {
      queryParams: {
        page,
      },
    });
  }
}

const MessageService = new _MessageService();

export default MessageService;
