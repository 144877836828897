import { Account } from "@app/types";
import { formatMoneyUSDT } from "@app/utils/util";
import { Images } from "@assets/images";

function HeaderProfile({ myInfo }: { myInfo: Account }) {
  return (
    <div className="flex items-center gap-4 mt-2">
      <img className="w-16 rounded-[50%]" src={Images.Logo2.default} alt="" />
      <div>
        <div className="text-[16px]">{myInfo?.phone}</div>
        <div className="text-[12px] text-[#EAECEF]">ID: {myInfo?.uid}</div>
        <div className="flex gap-1 text-[12px] text-[#EAECEF]">
          USDT:
          <div className="text-[#F0B90B]">
            {formatMoneyUSDT(myInfo?.balance || 0)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderProfile;
