// Symbol.tsx
import { getLanguageKeyForChart, Language, LANGUAGE_KEY } from "@app/constants";
import StorageService from "@core/services/storage";
import React, { useEffect, useRef, memo } from "react";

const Symbol: React.FC = () => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!container.current) return;

    const l = StorageService.get(LANGUAGE_KEY);

    const language = getLanguageKeyForChart(l as Language);

    const script = document.createElement("script");

    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        ["MEXC:BTCUSDT|1D"], 
        ["Apple", "AAPL|1D"],
        ["Google", "GOOGL|1D"],
        ["Microsoft", "MSFT|1D"],
      ],
      chartOnly: false,
      width: "100%",
      height: "400",
      locale: language,
      colorTheme: "dark",
      autosize: true,
      showVolume: false,
      showMA: false,
      hideDateRanges: false,
      hideMarketStatus: false,
      hideSymbolLogo: false,
      scalePosition: "right",
      scaleMode: "Normal",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
      fontSize: "10",
      noTimeScale: false,
      valuesTracking: "1",
      changeMode: "price-and-percent",
      chartType: "area",
      maLineColor: "#2962FF",
      maLineWidth: 1,
      maLength: 9,
      headerFontSize: "medium",
      lineWidth: 2,
      lineType: 0,
      dateRanges: ["1d|1", "1m|30", "3m|60", "12m|1D", "60m|1W", "all|1M"],
    });

    container.current.appendChild(script);
  }, [container]);

  return (
    <div className="tradingview-widget-container relative" ref={container}>
      <div className="tradingview-widget-container__widget"></div>
      <div
        style={{
          position: "absolute",
          left: "8px",
          bottom: "3px",
          width: "35px",
          height: "40px",
          // backgroundColor: "rgba(1,1,1,0.4)",
          backgroundColor: "transparent",
        }}
      ></div>
    </div>
  );
};

export default memo(Symbol);
