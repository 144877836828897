import Pagination from "@app/components/pagination";
import { DEFAULT_PAGE, DEFAULT_TOTAL_PAGES } from "@app/constants";
import LogService from "@app/services/http/log.service";
import { Log } from "@app/types";
import { formatDate } from "@app/utils/util";
import { Images } from "@assets/images";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function LogManagement() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [logs, setLogs] = useState<Log[]>([]);

  const { subscribeUntilDestroy } = useObservable();
  const navigate = useNavigate();

  useEffect(() => {
    subscribeUntilDestroy(LogService.getLogs(page), (res) => {
      if (res.data.length) {
        setLogs(res.data);
        setTotalPages(res.total_page);
      } else {
        setLogs([]);
      }
    });
  }, [page]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  return (
    <div className="fade-in w-full bg-[#181a20] px-4 h-[100%] mx-auto pb-[67px]">
      <div className="flex justify-between w-full mb-4 pt-4">
        <i
          onClick={() => navigate(-1)}
          className="fa-solid fa-chevron-left text-white"
        ></i>
      </div>
      <div className="px-2 overflow-x-auto">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <div className="mt-2">
            {!!logs.length &&
              logs.map((log) => (
                <div
                  key={log._id}
                  className="border-gray-200 text-sm p-4 mt-2 hover:bg-[#51b83c2c] text-white bg-[#1e222d] rounded-lg"
                >
                  <div className="text-[12px] flex items-center justify-between">
                    <div className="flex items-center">
                      <div>
                        {formatDate(log.createdAt).toString().split(" ")[0]}
                      </div>
                      <img className="w-4" src={Images.Dot.default} alt="" />
                      <div>
                        {formatDate(log.createdAt).toString().split(" ")[1]}
                      </div>
                    </div>
                    <img className="w-4" src={Images.Seen.default} alt="" />
                  </div>
                  <div className="text-[14px] whitespace-pre-line">
                    {log.message}
                  </div>
                </div>
              ))}
          </div>
          {!logs.length && (
            <div className="flex justify-center text-white h-screen">
              Không có dữ liệu
            </div>
          )}
        </div>
      </div>
      {totalPages > 1 && (
        <div className="flex justify-center mt-3 items-center">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

export default LogManagement;
