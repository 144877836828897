import { SocketEvent } from "@app/constants";
import { Message, TransactionTimerResponse } from "@app/types";
import { Kline } from "@app/types/kline.type";
import { Environment } from "@environments/environment";
import { Observable } from "rxjs";
import { io, ManagerOptions, Socket, SocketOptions } from "socket.io-client";

class _SocketService {
  private socket!: Socket;

  public connect(options?: Partial<ManagerOptions & SocketOptions>) {
    const socketUrl = Environment.SOCKET_URL as string;

    const newOptions: Partial<ManagerOptions & SocketOptions> = {
      ...options,
    };

    if (!newOptions.transports || !newOptions.transports.length) {
      newOptions.transports = ["polling"];
    }

    if (!this.socket) {
      this.socket = io(socketUrl, newOptions);
    }
  }

  public onKline(): Observable<Kline> {
    return new Observable((observer) => {
      if (!this.socket) return;

      this.socket.on(SocketEvent.KLINE, (data) => observer.next(data));
    });
  }

  public onTransactionTimer(
    transactionId: string
  ): Observable<TransactionTimerResponse> {
    return new Observable((observer) => {
      if (!this.socket) return;

      this.socket.on(SocketEvent.TRANSACTION + transactionId, (data) =>
        observer.next(data)
      );
    });
  }

  // public onTransactionResult(transactionId: string): Observable<any> {
  //   return new Observable((observer) => {
  //     if (!this.socket) return;

  //     this.socket.on(SocketEvent.TRANSACTION_RESULT + transactionId, (data) =>
  //       observer.next(data)
  //     );
  //   });
  // }

  public onMessage(): Observable<Message> {
    return new Observable((observer) => {
      if (!this.socket) return;

      this.socket.on(SocketEvent.THREAD, (data) => observer.next(data));
    });
  }

  public emit(event: string, data: any) {
    if (!this.socket) return;

    this.socket.emit(event, data);
  }
}

const SocketService = new _SocketService();

export default SocketService;
