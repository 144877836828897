import { getLanguageKeyForChart, Language, LANGUAGE_KEY } from "@app/constants";
import StorageService from "@core/services/storage";
import React, { memo, useEffect, useRef } from "react";

const MiniSymbolOverview: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const l = StorageService.get(LANGUAGE_KEY);

    const language = getLanguageKeyForChart(l as Language);

    const script = document.createElement("script");

    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbol: "MEXC:BTCUSDT",
      width: "100%",
      height: "400",
      locale: language,
      dateRange: "12M",
      colorTheme: "dark",
      isTransparent: false,
      autosize: false,
      largeChartUrl: "",
    });

    containerRef.current.appendChild(script);
  }, []);

  // const handleClick = (e: MouseEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  //   navigate(PathnameUrl.TRANSACTION); // Thay bằng URL mong muốn
  // };

  return (
    <div className="tradingview-widget-container relative" ref={containerRef}>
      <div className="tradingview-widget-container__widget"></div>
      <div
        // onClick={handleClick}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          // backgroundColor: "rgba(1,1,1,0.4)",
          backgroundColor: "transparent",
        }}
      ></div>
    </div>
  );
};

export default memo(MiniSymbolOverview);
