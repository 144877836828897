import ReactDOM from "react-dom/client";
import App from "./app";
import "@core/styles/global.scss";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "@app/store";
import i18next from "i18next";

import global_en from "./translations/en/global.json";
import global_vi from "./translations/vi/global.json";
import global_zh from "./translations/zh/global.json";
import global_de from "./translations/de/global.json";
import global_fr from "./translations/fr/global.json";
import global_ja from "./translations/ja/global.json";
import global_ko from "./translations/ko/global.json";
import { I18nextProvider } from "react-i18next";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "vi",
  resources: {
    en: {
      global: global_en,
    },
    vi: {
      global: global_vi,
    },
    zh: {
      global: global_zh,
    },
    de: {
      global: global_de,
    },
    fr: {
      global: global_fr,
    },
    ja: {
      global: global_ja,
    },
    ko: {
      global: global_ko,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </HelmetProvider>
  </Provider>
);
