// TradingViewScreener.tsx
import {
  getLanguageKeyForChart,
  Language,
  LANGUAGE_KEY,
  PathnameUrl,
} from "@app/constants";
import StorageService from "@core/services/storage";
import { memo, MouseEvent, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

function CryptocurrencyMarket() {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const l = StorageService.get(LANGUAGE_KEY);

    const language = getLanguageKeyForChart(l as Language);

    const script = document.createElement("script");

    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "1000",
      defaultColumn: "overview",
      screener_type: "crypto_mkt",
      displayCurrency: "USDT",
      colorTheme: "dark",
      locale: language,
    });

    containerRef.current.appendChild(script);

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = ""; // Remove script on cleanup
      }
    };
  }, []);

  const navigate = useNavigate();
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    navigate(PathnameUrl.TRANSACTION); // Thay bằng URL mong muốn
  };

  return (
    <div className="tradingview-widget-container" ref={containerRef}>
      <div className="tradingview-widget-container__widget"></div>
      <div
        onClick={handleClick}
        style={{
          position: "fixed",
          top: "47px",
          left: "20px",
          bottom: 0,
          width: "170px",
          // backgroundColor: "rgba(1,1,1,0.4)",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      ></div>
    </div>
  );
}

export default memo(CryptocurrencyMarket);
